import { Card } from "react-bootstrap";
import DrewsProgressBar from '../DrewsProgressBar';
import RatingStar from './RatingStar';
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getRatings } from "../../redux/slices/users";

const Rating = (props) => {

    const dispatch = useDispatch()

    const [rating, setRating] = useState(0)
    const [reviews, setReviews] = useState([])

    useEffect(() => {
        dispatch(getRatings())
        .then((response) => {
            if (response?.payload?.results) {
                setReviews(response.payload.results)
            }
            if (response?.payload?.rating) {
                setRating(response.payload.rating)
            }
        })
    }, [])

    return (
        <Card className="border-0 shadow-sm rounded-16">
            <Card.Header className="border-0 bg-white">
                <p className="m-0 title">RATINGS</p>
            </Card.Header>
            <Card.Body>
                <div className="d-flex justify-content-start">
                    <div className="w-50">
                        <RatingStar  
                            showCounter={props.showCounter || false}
                            count={rating.overall_rating || 0}
                            size={props.size || null}
                        />
                        <p className="text-primary mt-3">
                            <i className="lni lni-users" style={{ fontSize: "20px"}}></i> <small>{reviews?.length}</small>
                        </p>
                    </div>
                    <DrewsProgressBar
                        excellent={rating.excellent}
                        better={rating.better}
                        very_good={rating.very_good}
                        good={rating.good}
                        poor={rating.poor}
                    />
                </div>
            </Card.Body>
        </Card>
    );
}

export default Rating;
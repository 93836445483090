import { Row, Col, Container, Card, Button, Table, Dropdown, FormCheck, Form, Collapse, Spinner, Nav } from "react-bootstrap";
import Layout from "../../../components/Layout";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { getMemberNotifications, markAsReadItem } from "../../../redux/slices/managers.slice";
import toast from "react-hot-toast";
import dateFormatter from "../../../utils/dateFormatter";

const Notifications = () => {

    const dispatch = useDispatch()

    const [data, setData] = useState([])
    const [page, setPage] = useState('inbox')
    const [loading, setLoading] = useState(false)
    const [readMessages, setReadMessages] = useState([])
    const [unReadMessages, setUnReadMessages] = useState([])

    const { message } = useSelector((state) => state.message)

    useEffect(() => {
        loadNotifications('False')
    }, [])


    const loadNotifications = (option) => {
        dispatch(getMemberNotifications(option))
        .then((response) => {
            if(response.payload?.results) {
                let unread = response.payload.results.filter(item => item.read === false)
                let readMessages = response.payload.results.filter(item => item.read === true)
                setData(response.payload.results)
                setReadMessages(readMessages)
                setUnReadMessages(unread)
            }
        })
    }

    // const navigate = useNavigate()

    const setPageNav = (item) => {
        setPage(item)
        if(item === 'read') {
            loadNotifications('True')
        }
        else {
            loadNotifications('False')
        }
    }

    const readNotification = (id) => {
        dispatch(markAsReadItem(id))
        .then((res) => {
            if (res.payload?.success === true) {
                toast.success('Read successfully')
                loadNotifications('False')
            }
        })
    }

    return (
        <Layout>
            <>
                <Row>
                    <Col md={3} className="bg-white full-height">
                        <Container className="p-4 py-5">
                            <h3 className="border-bottom pb-2">Alerts</h3>
                            <div className="mt-3 mb-3 sidebar-mini">
                                <Nav>
                                    <ul className="p-0">
                                        <li className="nav-item">
                                            <button
                                                type="button" 
                                                size="sm" 
                                                className={`nav-link px-2 btn-plain  ${page === 'all' ? 'active text-white' : ''}`} 
                                                onClick={ () => setPageNav('all') }
                                            >
                                                <FontAwesomeIcon icon="inbox"/>
                                               <span className="ms-2">All</span> 
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                type="button" 
                                                size="sm" 
                                                className={`nav-link px-2 btn-plain  ${page === 'unread' ? 'active text-white' : ''}`} 
                                                onClick={ () => setPageNav('unread') }
                                            >
                                                <FontAwesomeIcon icon="envelope"/>
                                               <span className="ms-2">Unread</span> 
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                type="button" 
                                                size="sm" 
                                                variant={ page === 'read' ? 'primary' : `light`} 
                                                className={`nav-link px-2 btn-plain ${page === 'read' ? 'active text-white' : ''}`} 
                                                onClick={ () => setPageNav('read') }
                                            >
                                                <FontAwesomeIcon icon="envelope-open" />
                                                <span className="ms-2">Read</span>
                                                
                                            </button>
                                        </li>
                                    </ul>
                                </Nav>
                            </div>
                        </Container>
                    </Col>
                    <Col md={9}>
                        <Container fluid className="mt-5">
                            <Card className="border-0">
                                <Card.Body className="p-2 table-responsive-lg">
                                    <Table size="sm" className="table-color table-stripedd">
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Message</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data?.length ? (
                                                    data?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <p className="m-0 font-bold">{item.title}</p>  
                                                                <small className="text-primary font-bold">{dateFormatter(item.date)}</small>
                                                            </td>
                                                            <td>  
                                                                {item.message}
                                                            </td>
                                                            <td>
                                                                {
                                                                    !item.read ? (
                                                                        <Button 
                                                                            type="button" 
                                                                            size="sm"
                                                                            variant="primary"
                                                                            onClick={ () => readNotification(item.id) }
                                                                        >
                                                                            Mark as read
                                                                        </Button>
                                                                    ) :
                                                                    (
                                                                        'Read'
                                                                    )
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                )
                                                : 
                                                (
                                                    <tr>
                                                        <td colSpan={5} className="text-center text-muted">No data found</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Container>
                    </Col>
                </Row>
            </>
        </Layout>
    );
}

export default Notifications;
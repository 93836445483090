import { Row, Col, Container, Card, Spinner, Button, Form, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import formatCurrency from "../../../utils/currencyFormmter";
import dateFormatter from "../../../utils/dateFormatter";
import { getTransferCharges } from "../../../redux/slices/account.service";
import { PaystackButton } from "react-paystack";
import AmountInput from "../../../components/AmountInput";

const LoyaltyPaycodeManagement = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [payload, setPayload] = useState({
        code: '',
        secret: '',
    })
    const [loading, setLoading] = useState(false)
    const [active_tab, setActive] = useState('')
    const [paycode, setPaycode] = useState({})
    const [paystackKey, setPaystackKey] = useState('')
    const [charges, setSystemCharges] = useState(0)
    const [errMessage, setErrMessage] = useState('')


    const componentProps = {
        email: paycode?.customer_email,
        amount: parseFloat(payload.amount) * 100,
        metadata: {
            phone: paycode?.customer_phone,
        },
        // publicKey: process.env.REACT_APP_PAYSTACK_PK,
        publicKey: paystackKey,
        text: "Submit",
        onSuccess: (data) =>  {
            topupVoucher(data.trxref)
        },   
        // onClose: () => alert("Wait! You need this oil, don't go!!!!"),
    }

    useEffect(() => {
        getThirdPartyKeys()
    }, [])


    const handleInput = (event) => {
        const {name, value} = event.target
        setPayload((prevState) => ({...prevState, [name]: value}))
    }

    const getVoucher = () => {
        setLoading(true)
        axios.get(`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_DEV}/api-v1/web_loyalty_voucher/?code=${payload.code}&secret=${payload.secret}`)
        .then((response) => {
            if(response.data.data) {
                const responseData = response.data.data
                const [data] = responseData
                setPaycode(data)
                setPayload((prevState) => ({...prevState, merchant_id: data.merchant_id}))
            }
        })
        .catch((error) => {
            setPaycode({})
            Swal.fire({
                icon: 'error',
                text: error.response?.data?.message
            })
        })
        .finally(() => setLoading(false))
    }

    const updateVoucher = () => {
        setLoading(true)
        axios.put(`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_DEV}/api-v1/web_loyalty_voucher/`, payload)
        .then((response) => {
            if(response.status) {
                Swal.fire({
                    icon: "success",
                    text: response.message,
                })
            }
        })
        .catch((error) => {
            Swal.fire({
                icon: 'error',
                text: error.response?.data?.message
            })
        })
        .finally(() => setLoading(false))
    }

    const topupVoucher = (reference) => {
        setLoading(true)
        axios.post(`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_DEV}/api-v1/web_loyalty_voucher/`, {
            reference,
            merchant_id: paycode.merchant_id,
            code: paycode.code
        })
        .then((response) => {
            if(response.status) {
                Swal.fire({
                    icon: "success",
                    text: response.message,
                })
            }
        })
        .catch((error) => {
            Swal.fire({
                icon: 'error',
                text: error.response?.data?.message
            })
        })
        .finally(() => setLoading(false))
    }

    const getThirdPartyKeys = () => {
        const credentials = `${process.env.REACT_APP_CLIENT_USER}:${process.env.REACT_APP_CLIENT_KEY}`
        const base64Credentials = btoa(credentials)
        axios.get(`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_DEV}/api-v1/third_party_keys/`, {
            headers: {
                Authorization: `Basic ${base64Credentials}`
            }
        })
        .then(response => {
            if(response.data.data !== 'undefined') {
                const data = response.data.data
                if(data.pay_stack_live_key) {
                    setPaystackKey(data.pay_stack_public_key)
                }
            }
        }) 
        .catch(err => {
            // console.log(err)
        })
    }

    const getSystemCharges = () => {
        dispatch(getTransferCharges())
        .then(response => {
            if(response.payload !== 'undefined') {
                const data = response.payload
                if(data.length) {
                    const charge = data.find(item => item.name === 'withdrawal')
                    if(charge.value) {
                        setSystemCharges(charge.value)
                    }
                }
            }
        })
    }

    const handleAmountUpdate = (data) => {
        const cleanAmount = data.split(',').join('')
        if(parseFloat(cleanAmount) <= 100) {
            setErrMessage('Amount must be greater than 100')
            return;
        }
        setErrMessage('')
        setPayload((prevState) => ({...prevState, amount: cleanAmount}))
    }

    return (
        <Container className="mt-4 px-4 mb-4">
            <Row className="mt-5 justify-content-center">
                <Col md={7}>
                    <Card className="border-0 shadow-sm">
                        <Card.Header className="bg-primary text-white">
                            Loyalty Voucher Management 
                        </Card.Header>
                        <Card.Body className="table-responsive-lg">
                            <div className="d-flex-align-items-center flex-wrap">
                                <Button type="button" size="sm" variant={active_tab === "" ? "outline-primary" : "light"} className="me-1 mb-1" onClick={() => setActive('')}>Voucher Details</Button>
                                <Button type="button" size="sm" variant={active_tab === "topup" ? "outline-primary" : "light"} className="mb-1" onClick={() => setActive('topup')} disabled={!paycode.merchant_id}>Topup Voucher</Button>
                                <Button type="button" size="sm" variant={active_tab === "change" ? "outline-primary" : "light"} className="ms-1 mb-1" onClick={() => setActive('change')} disabled={!paycode.merchant_id}>Change Voucher Secret</Button>
                            </div>
                            <Col>
                                {
                                    active_tab === "" ? (
                                        <>
                                            <Row className="mt-4">
                                                <Form.Group md={6} as={Col}>
                                                    <Form.Label className="m-0">Voucher Code</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder="Voucher code"
                                                        name="code"
                                                        size="lg"
                                                        onChange={handleInput}
                                                    />
                                                </Form.Group>
                                                <Form.Group md={6} as={Col}>
                                                    <Form.Label className="m-0">Voucher Secret</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="password"
                                                        placeholder="*****"
                                                        name="secret"
                                                        size="lg"
                                                        onChange={handleInput}
                                                        autoComplete="true"
                                                    />
                                                </Form.Group>
                                                <Form.Group md={12} as={Col} className="text-right mt-2">
                                                    <Button 
                                                        type="button" 
                                                        variant="primary" 
                                                        disabled={payload.code === '' || payload.secret === ''}
                                                        onClick={getVoucher}
                                                    >
                                                        Submit <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                                    </Button>
                                                </Form.Group>
                                            </Row>
                                            
                                            {
                                                paycode?.merchant_id && (
                                                    <Table>
                                                        <tbody>
                                                            <tr>
                                                                <td>Balance</td>
                                                                <td>{formatCurrency(paycode?.balance).with_currency}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Amount Used</td>
                                                                <td>{formatCurrency(paycode?.used_amount).with_currency}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Customer Name</td>
                                                                <td>{paycode?.customer_name}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Customer Email</td>
                                                                <td>{paycode?.customer_email}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Customer Phone Number</td>
                                                                <td>{paycode?.customer_phone}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Voucher Expires</td>
                                                                <td>{dateFormatter(paycode?.expires_at)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                )
                                            }
                                            
                                        </>
                                    ) : null
                                }

                                {
                                    active_tab === 'change' ? (
                                        <Row className="mt-4">
                                            <Form.Group md={6} as={Col} className="mb-2">
                                                <Form.Label className="m-0">Customer</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Customer"
                                                    name="code"
                                                    size="lg"
                                                    readOnly
                                                    value={paycode?.customer_name}
                                                />
                                            </Form.Group>
                                            <Form.Group md={6} as={Col} className="mb-2">
                                                <Form.Label className="m-0">Voucher Code</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Voucher code"
                                                    name="code"
                                                    size="lg"
                                                    readOnly
                                                    value={paycode?.code}
                                                />
                                            </Form.Group>
                                            <Form.Group md={6} as={Col} className="mb-2">
                                                <Form.Label className="m-0">Voucher Secret</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="secret"
                                                    name="secret"
                                                    size="lg"
                                                    value={paycode?.secret}
                                                    onChange={handleInput}
                                                />
                                            </Form.Group>
                                            <Form.Group md={6} as={Col} className="mb-2">
                                                <Form.Label className="m-0">New Secret</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="secret"
                                                    name="new_secret"
                                                    size="lg"
                                                    onChange={handleInput}
                                                />
                                            </Form.Group>
                                            <Form.Group md={12} as={Col} className="text-right mt-2">
                                                <Button 
                                                    type="button" 
                                                    variant="primary" 
                                                    disabled={payload.code === '' || payload.secret === '' || payload.new_secret === ''}
                                                    onClick={updateVoucher}
                                                >
                                                    Submit <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                                </Button>
                                            </Form.Group>
                                        </Row>
                                    ) : null
                                }

                                {
                                    active_tab === 'topup' ? (
                                        <Row className="mt-4">
                                            <Form.Group md={6} as={Col} className="mb-2">
                                                <Form.Label className="m-0">Amount</Form.Label>
                                                {/* <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="amount"
                                                    name="amount"
                                                    size="lg"
                                                    onChange={handleInput}
                                                /> */}
                                                <AmountInput
                                                    onChange={handleAmountUpdate}
                                                />
                                                {
                                                    errMessage !== '' ? (
                                                        <p className="text-danger">{errMessage}</p>
                                                    ) : null
                                                }
                                            </Form.Group>
                                            <Form.Group md={12} as={Col} className="text-right mt-2">
                                                {
                                                    payload.amount <= 100 || payload.amount === undefined ? (
                                                        <Button 
                                                            type="button" 
                                                            variant="primary" 
                                                            disabled
                                                            className="d-block w-100"
                                                        >
                                                            Submit
                                                        </Button>
                                                    ) : 
                                                    <PaystackButton disabled className="btn btn-primary form-control" {...componentProps} />
                                                }
                                            </Form.Group>
                                        </Row>
                                    ) : null
                                }
                            </Col>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>
        </Container>
    );
}

export default LoyaltyPaycodeManagement;
import { Row, Col, Container, Card, Table, Button } from "react-bootstrap";
import Layout from "../../components/Layout";
// import avatar from '../../assets/images/avatar.svg';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getRewards } from "../../redux/slices/users";
import formatCurrency from "../../utils/currencyFormmter";
import dateFormatter from "../../utils/dateFormatter";
import gift from "../../assets/images/gift.svg";
import share from "../../assets/images/share.svg";


const Rewards = () => {

    const dispatch = useDispatch()

    const { business } = useSelector((state) => state.user)

    const [rewards, setRewards] = useState([])
    const [analytics, setAnalytics] = useState({})
    const [referral_link, setReferralLink] = useState('')


    useEffect(() => {
        loadRewards()
        setReferralLink('https://bs-staging.wallx.co/?ref='+business?.referral_code ?? 'Loading...')
    }, [])

    const loadRewards = () => {
        dispatch(getRewards())
        .then((response) => {
            // console.log(response)
            if(response.payload?.results) {
                setRewards(response.payload.results)
            }
            if(response.payload?.analytics) {
                setAnalytics(response.payload.analytics)
            }
        })
    }

    const handleShare = async (link, text) => {
        if (navigator.share) {
          try {
            await navigator.share({
              title: text,
              text: 'Refer and earn',
              url: link
            });
            // console.log('Link shared successfully');
          } catch (error) {
            console.error('Error sharing link:', error);
          }
        } else {
          console.log('Web Share API is not supported in this browser');
        }
    };

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <Row>
                    <Col lg={4} md={4} className="mb-3">
                        <div className="data-card rounded-16 bg-white p-4">
                            <p>Commission Earning</p>
                            <h3 className="text-secondary font-bold">{formatCurrency(analytics?.commission).with_currency || 0}</h3>
                            <div className="elipse">
                                <svg xmlns="http://www.w3.org/2000/svg" width="101" height="80" viewBox="0 0 101 80" fill="none">
                                    <circle opacity="0.2" cx="100" cy="-20" r="100" fill="#B2B1FF"/>
                                </svg>
                            </div>
                            <div className="elipse">
                                <svg xmlns="http://www.w3.org/2000/svg" width="87" height="64" viewBox="0 0 87 64" fill="none">
                                    <circle opacity="0.2" cx="100" cy="-36" r="100" fill="#B2B1FF"/>
                                </svg>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={4} className="mb-3">
                        <div className="data-card rounded-16 bg-white p-4">
                            <p>Referral Points</p>
                            <h3 className="text-dark font-bold">{analytics?.referral_points || 0}</h3>
                            <div className="elipse-down">
                                <svg xmlns="http://www.w3.org/2000/svg" width="129" height="65" viewBox="0 0 129 65" fill="none">
                                    <circle opacity="0.2" cx="100" cy="100" r="100" fill="#B2B1FF"/>
                                </svg>
                            </div>
                            <div className="elipse-down">
                                <svg xmlns="http://www.w3.org/2000/svg" width="110" height="50" viewBox="0 0 110 50" fill="none">
                                    <circle opacity="0.2" cx="100" cy="100" r="100" fill="#B2B1FF"/>
                                </svg>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={4} className="mb-3">
                        <div className="data-card rounded-16 bg-white p-4">
                            <p>No. of Referrals</p>
                            <h3 className="text-success font-bold">{analytics?.number_of_referrals || 0}</h3>
                        </div>
                    </Col>
                    <Col md="12 mt-4">
                        <Card className="shaow-sm border-0 rounded-16">
                            <Card.Body>
                                <h5 className="title">REFERRAL HISTORY</h5>
                                <Table size="sm" className="table-color">
                                    <tbody>
                                        {
                                        rewards?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <p className="m-0 d-flex gap-2 align-items-center">
                                                            <img src={item.avatar} alt="avatar" width="30" className="rounded-circle"/>
                                                            <strong>
                                                                {item.name}
                                                            </strong>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        {dateFormatter(item.date)}
                                                    </td>
                                                    <td className="text-success font-bold">
                                                        {item.referral_point}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="12" className="mt-3">
                        <div className="rounded-16 px-3 py-4" style={{backgroundColor: '#B2B1FF'}}>
                            <div className="d-flex gap-2 align-items-center justify-content-between">
                                <div className="d-flex gap-2 align-items-center">
                                    <img src={gift} alt="gift" />
                                    <div className="ms-3">
                                        <h4 className="font-bold text-white">Refer and Earn</h4>
                                        <p className="text-white">Refer your friends and earn points when they make transactions</p>
                                    </div>
                                </div>
                                <div className="justify-self-end">
                                    <Button type="button" variant="light" className="bg-white text-primary" onClick={ () => handleShare(referral_link, "Refer and earn") }>
                                        Share link 
                                        <img src={share} alt="share" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
}

export default Rewards;
import { Row, Col, Container, Card, Form, Button, Table, Badge, Dropdown } from "react-bootstrap";
import Layout from "../../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../../components/table/Paginator";
import { useEffect, useState } from "react";
import avatar from '../../../assets/images/avatar.svg';
import receipt from '../../../assets/images/receipt.svg';
import DrewsModal from "../../../components/modal/DrewsModal";
import { useDispatch } from "react-redux";
import { getTransactionHistory } from "../../../redux/slices/managers.slice";
import formatCurrency from "../../../utils/currencyFormmter";
import dateFormatter from "../../../utils/dateFormatter";

const MemberTansactions = () => {


    const dispatch = useDispatch()

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const [activeTab, setActiveTab] = useState('all')
    const [month, setMonth] = useState(1);
    const [modalShow, setModalShow] = useState(false)
    const [transaction, setTransaction] = useState({})
    const [transactions, setTransactions] = useState([])
    const [results, setResults] = useState([])
    
    useEffect(() => {
        loadTransactions()
    }, [])

    const nextMonth = () => {
        if(month < 11) {
            setMonth((prev) => (prev === month.length ? prev : ++prev));
        }
    }

    const prevMonth = () => {
        if(month > 0 && month <= 11) {
            setMonth((prev) => (prev === month.length ? prev : --prev));
        }
    }

    const selectTransaction = (id) => {
        setModalShow(true)
        setTransaction(transactions?.filter(item => item.id === id)[0])
    }

    const loadTransactions = () =>  {
        dispatch(getTransactionHistory())
        .then((response) => {
            if(response.payload.results) {
                setTransactions(response.payload.results)
                setTimeout(() => {
                    filterTransactions(response.payload.results, 'all')
                }, 1000);
            }
        })
    }

    const filterTransactions  = (payload = transactions, value) => {

        if(value.toLowerCase().includes('voucher')) {
            setActiveTab('vouchers')
        }
        else if(value.toLowerCase().includes('invoice')) {
            setActiveTab('invoices')
        }
        else {
            setActiveTab(value)
        }

         
       const data = value === 'failed' ? 
            payload?.filter(trans => trans.status !== 'success') :
            (value !== 'all' ? payload?.filter(trans => trans.transaction_type === value) : payload) 
        setResults(data)
    }


    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <Col md={12} className="d-flex align-items-center justify-content-between">
                    <div>
                        <h3>Transactions History</h3>
                        <p>You have total {results?.length} transactions.</p>
                    </div>
                </Col>

                <Col md={12} xs={12} sm={12} lg={12} className="mt-2">
                        <Card className="shadow-sm">
                            <Card.Header className="d-flex align-items-center bg-transparent">
                            <h4 className="mb-0">All Transactions </h4> 
                            </Card.Header>
                            <Card.Body className="table-responsive">
                                <Table size="sm" className="table-color table-stripedd">
                                    <thead>
                                        <tr>
                                            <th>Details</th>
                                            <th>Order</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            results?.length ? (
                                                results?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <div className="m-0 d-flex gap-2 align-items-center">
                                                                <small className={item.status === 'success' ? 'drews-badge drews-badge-success-circle' : 'drews-badge drews-badge-error-circle'}>
                                                                    <i className="lni lni-arrow-top-right"></i>
                                                                </small>
                                                                <strong>
                                                                <p className="m-0">{item.note}</p>  
                                                                <small>{item.date}</small>
                                                                </strong>
                                                            </div>
                                                        </td>
                                                        <td>  
                                                            <p className="m-0">{item.order}</p>
                                                            <span className={'font-bold '+item.status === 'success' ? 'text-success' : 'text-danger'}>{item.transaction_type}</span>
                                                        </td>
                                                        <td>{formatCurrency(item.amount).with_currency} </td>
                                                        <td>
                                                            <p className="d-flex m-0 align-items-center justify-content-start">
                                                                <Badge 
                                                                    className={item.status === 'success' ? 'badge-active' : 
                                                                    (item.status === 'failed' ? 'badge-failed' : 
                                                                    (item.status === 'used' ? 'badge-used' : 'badge-pending'))}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                        <circle cx="5" cy="5" r="5" fill={
                                                                            item.status === 'success' ? "#008000" : 
                                                                            (item.status === 'failed' ? "#EB5757" : 
                                                                            (item.status === 'used' ? "#828282" : "#FAB941"))
                                                                        }
                                                                        />
                                                                    </svg>
                                                                    <span className="ps-1">
                                                                        {item.status}
                                                                    </span>
                                                                </Badge> 
                                                                <Dropdown className="ms-3">
                                                                    <Dropdown.Toggle variant="light" className="border-0 bg-white" size="sm" id="dropdown-basic">
                                                                        <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                    </Dropdown.Toggle>
        
                                                                    <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                        <Dropdown.Item 
                                                                            eventKey={1} 
                                                                            className="text-primary d-flex align-items-center gap-1" 
                                                                            onClick={() => selectTransaction(item.id)}
                                                                        >
                                                                        <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Details
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                ))
                                            )
                                            : 
                                            (
                                                <tr>
                                                    <td colSpan={5} className="text-center text-muted">No data found</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    {
                        results?.length ? (
                            <div className="table-responsive mt-2">
                                <Paginator/>
                            </div>
                        ) : null
                    }
                </Col>
                <DrewsModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    dismissable={true}
                    title={`Transaction #${transaction.order}`}
                    badge_text={transaction.type}
                >
                    <Row>
                        <Col md={6}>
                            <div className="modal-profile">
                                <ul>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="font-bold">Transaction Reference</small>
                                                {transaction.reference} 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="font-bold">Order ID</small>
                                                {transaction?.order} 
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="font-bold">Transaction Type</small>
                                                {transaction?.transaction_type}
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="font-bold">Status</small>
                                                <span 
                                                className={transaction.status === 'success' ? 'text-success font-bold' : 
                                                (transaction.status === 'failed' ? 'text-danger font-bold' : 
                                                (transaction.status === 'used' ? 'text-dark font-bold' : 'text-warning font-bold'))}
                                                >
                                                    {transaction.status}
                                                </span> 
                                            </p>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </Col>
                        <Col md={6} className="modal-profile">
                            <ul>
                                <li>
                                    <div>
                                        <p className="pt-2">
                                            <small className="font-bold">Amount</small>
                                            {formatCurrency(transaction.amount).with_currency}
                                        </p>
                                    </div>
                                </li>
                                <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="font-bold">Details</small>
                                                {transaction?.note || 'Nil'} 
                                            </p>
                                        </div>
                                    </li>
                                <li>
                                    <div>
                                        <p className="pt-2">
                                            <small className="font-bold">Transaction Date</small>
                                            {transaction.date}
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </DrewsModal>
            </Container>
        </Layout>
    );
}

export default MemberTansactions;
import { Row, Col, Container, Card, Button, Table, Badge, Modal, Form, Spinner } from "react-bootstrap";
import Layout from "../../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../../components/table/Paginator";
import { useEffect, useState } from "react";
import avatar from '../../../assets/images/avatar.svg';
import DrewsModal from "../../../components/modal/DrewsModal";
import { clearMessage } from "../../../redux/slices/message";
import { useDispatch, useSelector } from "react-redux";
import { applyPayCode, getVouchers } from "../../../redux/slices/managers.slice";
import Swal from "sweetalert2";
import formatCurrency from "../../../utils/currencyFormmter";
import CryptoJS from "crypto-js";
import { verifyWalletPin } from "../../../redux/slices/users";
import { checkBalance } from "../../../redux/slices/account.service";

const PaycodeVouchers = () => {

    const dispatch = useDispatch();
    const { user, isLoggedin } = useSelector((state) => state.auth)
    const { message } = useSelector((state) => state.message)

    const [showForm, setForm] = useState(false)
    const [show, setShow] = useState(false)
    const [showBalance, setShowBalance] = useState(false)
    const [payload, setPayload] = useState({})
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [genVoucher, setGenVoucher] = useState(false)
    const [paycodes, setPaycodes] = useState([])
    const [errors, setErrors] = useState({})
    const [analytics, setAnalytics] = useState({})
    const [decrypted, setDecrypted] = useState(null)
    const [paycode, setPaycode] = useState({})
    const [claim_code, setClaimPaycode] = useState(false)


    const secretPass = 'ZphGxxfW2t2q'

    useEffect(() => {
        loadPaycodes()
    }, [])

    const handleEncryption = (txt) => {
        return CryptoJS.AES.encrypt(JSON.stringify(txt), secretPass).toString()
    }

    const selectPaycode = (item) => {
        setPaycode(item)
       let encrypted = handleEncryption(item?.secret)
       setDecrypted(encrypted)
        setShow(true)
    }

    const handleInput = (event) => {
        const {name, value} = event.target
        if(name === 'dob') {
            const dateParts = value.split('-');
            const dob = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
            setPayload((prevState) => ({...prevState, [name]: dob}))
        }
        else if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^\d.]/g, ''))
            setPayload((prevState)=> ({...prevState,[name]:newValue}))
        }
        else {
            setPayload((prevState) => ({...prevState, [name]: value}))
        }
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else if (name ==='amount' && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }
    }


    const usePaycode = () => {
        if(!payload.amount && !payload.pin && !payload.secret)  { return }
        // console.log(payload)
        setLoading(true)
        dispatch(applyPayCode(payload))
        .then((response) => {
            setLoading(false)
            if(response.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                })
                .then(() => setClaimPaycode(false))
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
    }

    const loadPaycodes = () => {
        dispatch(getVouchers())
        .then((response) => {
            if(response.payload?.results) {
                setPaycodes(response.payload.results)
            }
            if(response.payload?.analytics) {
                setAnalytics(response.payload.analytics)
            }
        })
    }

    const verifyPin = () => {
        setLoading(true)
        dispatch(verifyWalletPin(payload))
        .then((response) => {
            if(response.payload?.success === true) {
                setDecrypted(paycode?.secret)
            }
            else if(response.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const paycodeBalance = () => {
        if(!payload.paycode && !payload.secret_word)  {  return }
        setLoading(true)
        dispatch(checkBalance(payload))
        .then((response) => {
            if(response.payload?.success) {
                Swal.fire({
                    icon: 'info',
                    text: response.payload?.message
                })
                .then(() => setShowBalance(false))
            }
        })
        .finally(() => setLoading(false))
    }
    

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <Row className="mb-2">
                    <Col md={6}>
                        <h3 className="mb-0">Paycode Payment</h3>
                        <p className="text-primary font-bold">You have claimed ₦0 paycodes.</p>
                    </Col>
                    <Col md={6}>
                        <div className="text-right">
                            <Button 
                                type="button" 
                                variant="light" 
                                className="text-white bg-primary border-0 mb-2 mx-2"
                                onClick={ () => setShowBalance(true)}
                            >
                                <span className="ps-2">Check Balance</span>
                            </Button>
                            <Button 
                                type="button" 
                                variant="light" 
                                className="text-white bg-primary border-0 mb-2"
                                onClick={() => setClaimPaycode(true)}
                            >
                                <FontAwesomeIcon icon='plus' />
                                <span className="ps-2">Claim Paycode</span>
                            </Button>
                        </div>
                    </Col>
                </Row>

                <div>
                    <Card className="shadow-sm">
                        <Card.Body className="table-responsive-lg table-striped">
                            <Table size="sm" className="table-color">
                                <thead>
                                    <tr>
                                        <th>Transaction ID</th>
                                        <th>Customer Name</th>
                                        <th>Paycode</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Date Created</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        paycodes?.length ? (
                                            paycodes.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {item.reference}
                                                        {/* <button
                                                            style={{border: "none", display: "block", backgroundColor: "transparent"}}
                                                            className="text-primary"
                                                            onClick={ () => selectPaycode(item) }
                                                        >
                                                            Reveal Secret Word
                                                        </button> */}
                                                    </td>
                                                    <td>
                                                        <strong>
                                                            {item.owner}
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        {item.token}
                                                    </td>
                                                    <td>
                                                        {formatCurrency(item.amount).with_currency}
                                                    </td>
                                                    <td>
                                                        <p className="d-flex m-0 align-items-center justify-content-between">
                                                            <Badge 
                                                                className={item.valid_detail ? 'badge-active' : 
                                                                (item.used === 'used' ? 'badge-used' : 
                                                                (!item.valid_detail ? 'badge-failed' : 'badge-pending')
                                                                )}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                    <circle cx="5" cy="5" r="5" fill={
                                                                        item.valid_detail ? "#008000" : 
                                                                        (item.used ? "#828282" : "#FAB941")
                                                                    }
                                                                    />
                                                                </svg>
                                                                <span className="ps-1">
                                                                    {item.valid_detail ? 'Active' : (item.used ? 'Used' : 'Expired')}
                                                                </span>
                                                            </Badge> 
                                                            {/* <Dropdown>
                                                                <Dropdown.Toggle variant="light" className="border-0 bg-white" size="sm" id="dropdown-basic">
                                                                    <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                </Dropdown.Toggle>
    
                                                                <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                    <Dropdown.Item 
                                                                        eventKey={1} 
                                                                        className="text-primary d-flex align-items-center gap-1" 
                                                                        onClick={() => paycodeSelection(item.id)}
                                                                    >
                                                                    <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Ticket
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item 
                                                                        eventKey={2} 
                                                                        className="d-flex align-items-center gap-1"
                                                                        onClick={ () => paycodeSelection(item.id) }
                                                                    >
                                                                        <FontAwesomeIcon icon="folder-open" /> Re-open Ticket
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown> */}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        {item.date}
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                        :
                                        (
                                            <tr>
                                                <td colSpan={5} className="text-center text-muted">No data found</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {
                        paycodes?.length ? (
                            <div className="table-responsive mt-2">
                                <Paginator/>
                            </div>
                        ) : null
                    }
                </div>

                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={claim_code}
                    onHide={() => setClaimPaycode(false)}
                    backdrop="static"
                >
                    <Modal.Header closeButton>Claim Voucher</Modal.Header>
                    <Modal.Body className="px-4">
                        <Form>
                            <Row>
                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Label className="mb-0">PayCode</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        placeholder="PayCode"
                                        name="pin"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                        minLength={6}
                                        maxLength={6}
                                    />
                                    {
                                        errors['pin'] && 
                                        <Form.Text className="text-danger">{errors['pin']}</Form.Text>
                                    }
                                </Form.Group>

                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Label className="mb-0">Secret Word</Form.Label>
                                    <Form.Control
                                        type="password"
                                        required
                                        placeholder="Secret Word"
                                        name="secret"
                                        maxLength={6}
                                        minLength={6}
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                    />
                                    {
                                        errors['secret'] && 
                                        <Form.Text className="text-danger">{errors['secret']}</Form.Text>
                                    }
                                </Form.Group>

                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Label className="mb-0">Amount</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        placeholder="0.00"
                                        name="amount"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                        value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}
                                    />
                                    {
                                        errors['amount'] && 
                                        <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                    }
                                </Form.Group>
                                <Form.Group 
                                    as={Col} 
                                    md="12" 
                                    controlId="validationCustom011" 
                                    className="mt-4 d-grid"
                                >
                                    <Button 
                                        variant="primary" 
                                        size="lg" 
                                        onClick={usePaycode}
                                        disabled={Object.keys(errors).length > 0}
                                    >
                                        Claim PayCode <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                    </Button>
                                </Form.Group>
                            </Row>
                        </Form>
                    </Modal.Body>
                </Modal>
                <DrewsModal
                    show={showBalance}
                    onHide={() => setShowBalance(false)}
                    size="md"
                    dismissable={true}
                    title="Voucher Balance"
                >
                    <Form>
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Voucher's Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="PayCode"
                                    name="paycode"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    minLength={6}
                                    maxLength={6}
                                />
                                {
                                    errors['paycode'] && 
                                    <Form.Text className="text-danger">{errors['paycode']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Secret Word</Form.Label>
                                <Form.Control
                                    type="password"
                                    required
                                    placeholder="Secret Word"
                                    name="secret_word"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['secret_word'] && 
                                    <Form.Text className="text-danger">{errors['secret_word']}</Form.Text>
                                }
                            </Form.Group>
                                <p className="text-danger text-sm">
                                    {errorMessage}
                                </p>
                            <Form.Group 
                                as={Col} 
                                md="12" 
                                controlId="validationCustom011" 
                                className="mt-4 d-grid"
                            >
                                <Button 
                                    variant="primary" 
                                    size="lg" 
                                    onClick={paycodeBalance}
                                    disabled={Object.keys(errors).length > 0}
                                >
                                    Check Balance <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>
            </Container>
        </Layout>
    );
}

export default PaycodeVouchers;
import { useState, useEffect } from "react";
import { Col, Container, Form, Row, Button, InputGroup, Spinner } from "react-bootstrap";
import Logo from "../../components/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../../redux/slices/auth";
import { getProfile } from "../../redux/slices/users";
import { clearMessage } from '../../redux/slices/message.js'
// import toast from 'react-hot-toast';
import AlertModal from "../../components/modal/AlertModal";
import Swal from "sweetalert2";

const Login = () => {

    const [validated, setValidated] = useState(false);
    const [show_password, setShowPassword] = useState(false)
    const [is_active, setIsActive] = useState(false)
    const [payload, setPayload] = useState({email: '', password: ''})
    const [loading, setLoading] = useState(false)
    const [alertMessage, setAlertMessage] = useState(false)
    const [alertText, setAlertText] = useState(null)
    
    const navigate = useNavigate();

    const dispatch = useDispatch()

    const { isLoggedIn, user, isManager } = useSelector((state) => state.auth)
    const { message } = useSelector((state) => state.message)

    useEffect(() => {
        if(isLoggedIn && user.user_type === 'owner') {
            // navigate('/dashboard')
            dispatch(getProfile())
            .then((e) => {
                const data = e.payload
                if(data?.email && !data?.store_created) {
                    navigate('/profile-setup') 
                }
               else if(data && data.email && data.verified == false) {
                    Swal.fire({
                        icon: 'warning',
                        text: 'Your account is not verified. Please contact support',
                    })
                    dispatch(logout())
                    return;
                } 
                else if(data && data.email && data.account_locked) {
                    Swal.fire({
                        icon: 'warning',
                        text: 'Your account is currently restricted. Please contact support.',
                    })
                    dispatch(logout())
                    return;
                }
                else if(data?.email && data?.store_created) {
                    navigate('/dashboard')
                }
            })
        }
        else if(isLoggedIn) {
            navigate('/members/dashboard') 
        }
        // setTimeout(() => {
        // }, 1000);
        // isLoggedIn && !isManager ? navigate('/dashboard') : 
        // isLoggedIn && isManager ? navigate('/members/dashboard') 
    }, [])


    const handleSubmit = (event) => {
      const form = event.currentTarget;
    //   console.log(event)
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }

      setValidated(true);

      const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
      if(validated && pattern.test(payload.email)) {
        handleLogin(event)
      }

    };

    const togglePassword = () => {
        setShowPassword(!show_password)
    }

    const handleInput = (event) => {
        const { name, value } = event.target
        setPayload((prevState)=> ({...prevState,[name]:value}))
        const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if((payload.email && payload.email.match(pattern)) && payload.password) 
            setIsActive(true)
        else 
            setIsActive(false)
    }

    const createAccount = () => {
        navigate('/signup')
    }

    const forgotPassword = () => {
        navigate('/forgot-password')
    }

    const handleLogin = async () => {
        setLoading(true)
        dispatch(login(payload))
        .then((response) => {
            // console.log("LOGIN", response)
            if (response.payload?.success === false) {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
                dispatch(clearMessage())
            }
            else if(response.payload?.success === true) {
                Swal.fire({
                    'icon': 'success',
                    text: response.payload?.message
                })
                dispatch(clearMessage())
                dispatch(getProfile())
                .then((e) => {
                    const data = e.payload
                    if(data?.email && !data?.store_created) {
                        navigate('/profile-setup') 
                    }
                   else if(data && data.email && data.verified == false) {
                        Swal.fire({
                            icon: 'warning',
                            text: 'Your account is not verified. Please contact support',
                        })
                        dispatch(logout())
                        return;
                    } 
                    else if(data && data.email && data.account_locked) {
                        Swal.fire({
                            icon: 'warning',
                            text: 'Your account is currently restricted. Please contact support.',
                        })
                        dispatch(logout())
                        return;
                    }
                    else if(data?.email && data?.store_created) {
                        navigate('/dashboard')
                    }
                })
            }
        })
        .finally(() => setLoading(false))
    }

    
    return (
        <Row className="full-height">
            <Col md={6} lg={6} sm={12} className="px-5">
                <Container className="mt-5 text-center px-5">
                    <div className="mt-3 mb-5">
                        <Logo variant="dark"/>
                    </div>
                    <h1 className="text-primary mt-5">👋 Welcome back, {user?.first_name || ''}</h1>
                    {/* <h1 className="text-primary mt-5">👋 Welcome back, Admin</h1> */}
                    <p className="text-dark">
                        Login to your dashboard to see some transactions/activities you might have missed.
                    </p>
                </Container>
                <Container className="px-5 px-md-5 mt-5">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={12} sm={12} lg={12} className="mb-3">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    size="lg"
                                    onChange={handleInput}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                <FontAwesomeIcon icon="fa fa-circle-info"/>
                                    <span className="px-1">Enter a valid email</span>
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md={12} sm={12} lg={12} className="mb-3">
                                <Form.Label>Password</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type={show_password ? "text" : "password"}
                                        placeholder="Password"
                                        required
                                        size="lg"
                                        name="password"
                                        onChange={handleInput}
                                        autoComplete="true"
                                    />
                                    <InputGroup.Text onClick={togglePassword}>
                                        <Button type="button" variant="light" className="border-0 input-group-btn-bg">
                                            <FontAwesomeIcon icon={!show_password ? "far fa-eye-slash" : "far fa-eye"} />
                                        </Button>
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Button 
                                    type="button" 
                                    variant="light" 
                                    className="bg-white border-0 text-primary"
                                    onClick={forgotPassword}
                                >
                                    Forgot password?
                                </Button>
                            </Form.Group>
                        </Row>
                        <div className="d-grid gap-3">
                            <Button 
                                type="button" 
                                size="lg" 
                                disabled={loading ? true : false}
                                onClick={handleLogin}
                            >
                                Login <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                            </Button>
                            <Form.Group className="mb-3 text-center">
                              <span className="text-muted">Don't have an account?</span>
                                <Button 
                                    type="button" 
                                    variant="light" 
                                    size="sm"
                                    className="bg-white border-0 text-primary"
                                    onClick={createAccount}
                                >
                                    Create Account
                                </Button>
                            </Form.Group>
                        </div>
                    </Form>
                </Container>
            </Col>
            <Col md={6} lg={6} sm={12} className="bg-primary p-0 d-sm-none d-md-block">
                <Container className="mt-5 mb-2 ">
                    <Col className="text-white text-center px-5">
                        <h2>Share , Spend with Ease</h2>
                        <p>
                            Join WallX to easily conduct socio-lifestyle transactions. Suitbale for both businesses and individuals
                        </p>
                    </Col>
                </Container>
                <div className="login-banner">
                    <img src={require('../../assets/images/landing_page/dashboard.png')} alt="dashboard" />
                </div>
            </Col>
            <AlertModal
                text={alertText}
                onHide={() => setAlertMessage(false)}
                onClick={() => setAlertMessage(false)}
                show={alertMessage}
                buttonText="Ok"
            >
                <FontAwesomeIcon className="text-success" icon="check-circle" style={{ fontSize: "54px" }} />
            </AlertModal>
        </Row>
    );
}


export default Login;
import { Row, Col, Container, Card, Button, Form, Spinner, FormGroup } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import DrewsModal from "../../components/modal/DrewsModal";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import formatCurrency from "../../utils/currencyFormmter";
import { useNavigate, useParams } from "react-router-dom";
import { createSales, getProduct } from "../../redux/slices/inventory.slice";
import dateFormatter from "../../utils/dateFormatter";

const ProductPreview = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams()


    const initialState = {
        loading: false,
        show_vendor_form: false,
        showStockForm: false,
        showForm: false,
        analytics: {},
        paginationData: {next: '', prev: ''},
        payload: {
            to_currency: 'NGN', 
            currency: 'USD', 
            amount: 1,
            page: 1,
            from_date: '',
            to_date: '',
            status: '',
            download: '',
            filter_by: '',
            sort_by: '',
            filter: '',
            gateway: 'FasePay',
        },
        errors: {},
        suppliers: [],
        products: [],
        product: {},
        edit_mode:  false,
        restockMode: false
    }

    const [state, setState] = useState(initialState)
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [request_status, setRequestStatus] = useState(false)
    const [totalAmount, setTotalAmount] = useState('0')

    const { invLogin } = useSelector((state) => state.inventory)
    const { user } = useSelector((state) => state.auth)
    const { business } = useSelector((state) => state.user)


    useEffect(() => {
        getStockProfile()
    }, [])

    const handleInput = (event) => {
        const {name, value} = event.target
        if(state.product.stock_level < value) {
            setState((prevState) => ({...prevState, errors: {quantity: 'Quantity cannot be more than available stock'}}))
            return;
        }
        setState((prevState) => ({...prevState, errors: {quantity: ''}}))
        setState((prevState) => ({...prevState, payload: { ...prevState.payload, [name]: value}}))
    }

    const sellProduct = () => {
        const payload = {
            product_id: state.product.id,
            quantity: state.payload.quantity,
            payment_method: state.payload.payment_method
        }
        setLoading(true)
        dispatch(createSales(payload))
        .then((response) => {
            if(response.payload?.status) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload.message,
                })
                .then(() => {
                    setState((prevState) => ({...prevState, restockMode: false}))
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: response.payload.message,
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const getStockProfile = () => {
        setLoading(true)
        dispatch(getProduct(id))
        .then((response) => {
            // console.log("PRODUCT", response)
            if(response.payload?.data) {
                setState((prevState) => ({...prevState,  product: response.payload.data}))
            }
        })
        .finally(() => setLoading(false))
    }

    const actionConfirmation = () => {
        Swal.fire({
            icon: 'info',
            text: 'Are you sure you want to sell this product?',
            showCancelButton: true,
            confirmButtonText: 'Yes, Sell',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                sellProduct()
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
    }

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <div className="mt-4">
                    <Card className="border-0 shadow-sm rounded-14">
                        <Card.Header>
                            <Row className="align-items-center justify-content-between">
                                <div className="col-md-6 d-flex align-items-center">
                                    <Button
                                        type="button"
                                        variant="primary"
                                        className="border-0 mb-2 me-2"
                                        onClick={() => navigate('/inventory/pricelist')}
                                    >
                                        <FontAwesomeIcon icon={'chevron-left'} /> 
                                        <span className="ps-2">Go Back</span>
                                    </Button>
                                    <h5 className="mb-2">Product Preview</h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <Button
                                        type="button"
                                        variant="primary"
                                        className="border-0 mb-2"
                                        onClick={() => navigate('/inventory/sales-tracker')}
                                    >
                                        <FontAwesomeIcon icon={'box'} /> 
                                        <span className="ps-2">Sales Tracker</span>
                                    </Button>
                                </div>
                            </Row>
                        </Card.Header>
                        <Card.Body className="table-responsive-lg data-container">
                            {
                                loading ? (
                                    <div className="in-page-loader">
                                        <Spinner size="lg" variant="primary"/>
                                    </div>
                                ) : null
                            }
                        <Row className="mb-3 product-details">
                            <Col md={6}>
                                <div className="text-center">
                                    <img src={state.product?.image} className="img-fluid" alt="Product details" />
                                </div>
                                <ul>
                                    <li>Product Name: {state.product?.name}</li>
                                    <li>SKU: {state.product?.sku}</li>
                                    <li>Date Added: {dateFormatter(state.product?.created_at)}</li>
                                    <li>Supply Date: {dateFormatter(state.product?.supply_date)}</li>
                                    <li>Quantity: {formatCurrency(state.product?.quantity).formatted}</li>
                                    <li>Available Quantity: {formatCurrency(state.product?.stock_level).formatted}</li>
                                    <li>Unit Price: {formatCurrency(state.product?.price).with_currency}</li>
                                    <li>Sales Price: {formatCurrency(state.product?.sales_price).with_currency}</li>
                                    <li>Restock Level: {formatCurrency(state.product?.restock_level).formatted}</li>
                                    <li>Status: <strong>{state.product?.status === 1 ? 'Instock' : 'Out of stock'}</strong> </li>
                                </ul>
                                <Button 
                                    type="button" 
                                    size="sm" 
                                    variant="primary"
                                    className="ms-2"
                                    onClick={() => setState((prevState) => ({...prevState, restockMode: true}))}
                                >
                                    Sell Product
                                </Button>
                            </Col>
                        </Row>
                        </Card.Body>
                    </Card>
                </div>

                <DrewsModal
                    show={state.restockMode}
                    onHide={() => setState((prevState) => ({...prevState, restockMode: false}))}
                    size="md"
                    dismissable={true}
                    title={`Sell ${state.product.name}`}
                >
                    <Row>
                        <FormGroup as={Col} md={6} sm={12} className="mb-3">
                            <span> Quantity</span> <span className="text-danger">*</span> 
                            <Form.Control
                                type="number"
                                name="quantity"
                                onKeyUp={handleInput}
                                placeholder="Enter your Product quantity"
                                required
                                min={1}
                            />
                            <small>Available quantity {formatCurrency(state.product.stock_level).formatted}</small>
                            {
                                state.errors['quantity'] && 
                                <Form.Text className="text-danger">{state.errors['quantity']}</Form.Text>
                            }                            
                        </FormGroup>

                        <FormGroup as={Col} md={6} sm={12} className="mb-3">
                            <span> Payment Method</span> 
                            <Form.Select
                                name="payment_method"
                                onChange={handleInput}
                                required
                                min={1}
                            >
                                <option value="Cash">Cash</option>
                                <option value="Debit Card">Card</option>
                                <option value="Bank Transfer">Bank Transfer</option>
                                <option value="Paycode Voucher">Paycode Voucher</option>
                            </Form.Select>
                            {
                                state.errors['quantity'] && 
                                <Form.Text className="text-danger">{state.errors['quantity']}</Form.Text>
                            }                            
                        </FormGroup>

                        <FormGroup as={Col} md={6} sm={6} className="mb-3">
                            <span>Sales Price</span>(₦)<span className="text-danger">*</span>
                            <Form.Control
                                type="text"
                                name="sale_price"
                                placeholder="Price"
                                min={1}
                                readOnly
                                value={formatCurrency(state.product.sales_price).formatted}
                            />
                            {
                                state.errors['sale_price'] && 
                                <Form.Text className="text-danger">{state.errors['sale_price']}</Form.Text>
                            }                       
                        </FormGroup>
                        <FormGroup as={Col} md={6} sm={6} className="mb-3">
                            <span>Sales Total</span>(₦)<span className="text-danger">*</span>
                            <Form.Control
                                type="text"
                                name="price"
                                placeholder="Total"
                                required
                                min={1}
                                readOnly
                                value={formatCurrency(parseFloat(state.payload.quantity) * parseFloat(state.product.sales_price)).formatted}
                            />
                            {
                                state.errors['price'] && 
                                <Form.Text className="text-danger">{state.errors['price']}</Form.Text>
                            }                       
                        </FormGroup>
                        <FormGroup as={Col} md={12} sm={12}>
                            <div className="d-grid">
                                <Button variant="primary" type="submit" onClick={actionConfirmation} disabled={state.errors['quantity']}>
                                    Proceed <Spinner animation={ request_status ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </div>
                        </FormGroup>
                    </Row>
                </DrewsModal>
            </Container>
        </Layout>
    );
}

export default ProductPreview;
import { Row, Col, Container, Card, Button, Table, Badge, Dropdown, Form, Spinner, Modal, FormGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../../components/table/Paginator";
import { useEffect, useState } from "react";
import DrewsModal from "../../../components/modal/DrewsModal";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import formatCurrency from "../../../utils/currencyFormmter";
import InventoryFilter from "../../../components/InventoryFilter";
import { useNavigate } from "react-router-dom";
import { addProduct, createSupplier, getAnalytics, getProducts, getSuppliers, loginCustomer, restock, setupInventory } from "../../../redux/slices/inventory.slice";
import Layout from "../../../components/Layout";

const StockInventory = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initialState = {
        loading: false,
        show_vendor_form: false,
        showStockForm: false,
        showForm: false,
        analytics: {},
        paginationData: {next: '', prev: ''},
        payload: {
            to_currency: 'NGN', 
            currency: 'USD', 
            amount: 1,
            page: 1,
            from_date: '',
            to_date: '',
            status: '',
            download: '',
            filter_by: '',
            sort_by: '',
            filter: '',
            gateway: 'FasePay',
        },
        errors: {},
        suppliers: [],
        products: [],
        restockMode: false,
        product: {}
    }

    const [state, setState] = useState(initialState)
    const [loading, setLoading] = useState(false)
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})
    const [request_status, setRequestStatus] = useState(false)

    const { invLogin } = useSelector((state) => state.inventory)
    const { user } = useSelector((state) => state.auth)
    const { business } = useSelector((state) => state.user)


    useEffect(() => {
        handleCreateOrganisation()
    }, [])

    useEffect(() => {
        loadStocks()
    }, [state.payload.page])
    // }, [state.payload.page])

    const manageProduct = (item, option) => {
        if(option === 'restock') {
            setState((prevState) => ({
                ...prevState,
                restockMode: !prevState.restockMode, product: item
            }))
        }
        else if(option === 'view') {
            navigate('/members/inventory/stock-details/'+item.id)
        }
    }

    const handleInput = (event) => {
        const {name, value} = event.target
         if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'));
            setState((prevState) => ({...prevState, payload: { ...prevState.payload, [name]: newValue}}))
        }
        else {
            setState((prevState) => ({...prevState, payload: { ...prevState.payload, [name]: value}}))
        }
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setState((prevState) => ({ ...prevState, errors:{...prevState.errors, [name]: `${name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} is required` }}))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setState((prevState) => ({ ...prevState, errors: { ...prevState.errors, [name]: 'Please enter a valid email address'}}))
        }
        else if (name ==='amount' && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setState((prevState) => ({ ...prevState, errors: {...prevState.errors, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }}))
        }
        else {
            event.target.style = "border-style: transparent"
            delete state.errors[name]
            setState((prevState) => ({ ...prevState, errors: state.errors }))
        }
    }

    const createVendor = () => {
        setLoading(true)
        dispatch(createSupplier(state.payload))
        .then((response) => {
            // console.log(response)
            if(response.payload?.status) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload.message,
                })
                loadSuppliers()
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: response.payload.message,
                })
            }
        })
        .finally(() => setLoading(false))
    }
    
    const handlePagination = (data) => {
        setState((prevState) => ({...prevState, payload: {...prevState.payload,  page: data}}))
    }

    const addStock = () => {
        setLoading(true)
        let formdata = new FormData()
        formdata.append('name', state.payload.product_name)
        formdata.append('quantity', state.payload.quantity)
        formdata.append('price', state.payload.price)
        formdata.append('sales_price', state.payload.sales_price)
        formdata.append('vendor_id', state.payload.vendor_id)
        formdata.append('unit', state.payload.unit)
        formdata.append('supply_date', state.payload.supply_date)
        formdata.append('expiry_date', state.payload.expiry_date)
        formdata.append('restock_level', state.payload.restock_level)
        formdata.append('description', state.payload.description)
        formdata.append('sku', state.payload.sku)
        formdata.append('image', state.image)

        dispatch(addProduct(formdata))
        .then((response) => {
            if(response.payload?.status && response.payload?.data) {
                Swal.fire({
                    icon: 'success',
                    text: response?.payload?.message
                })
                .then(() => {
                    setState((prevState) => ({...prevState, showStockForm: false,  image: '', product_image: '', payload: initialState.payload}))
                    loadStocks()
                    loadAnalytics()
                })
            }
            else {
                Swal.fire({
                    icon: 'error', 
                    text: response?.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }


    const loadAnalytics = () => {
        // setLoading(true)
        dispatch(getAnalytics())
        .then((response) => {
            // console.log(response)
            if(response.payload?.data) {
                setState((prevState) => ({...prevState,  analytics: response.payload.data}))
                loadSuppliers()
            }
        })
        .finally(() => setLoading(false))
    }

    const loadSuppliers = () => {
        dispatch(getSuppliers())
        .then((response) => {
            const result = response.payload?.data
            // console.log("Suppliers", result?.data)
            if(result?.data) {
                setState((prevState) => ({...prevState, suppliers:  result?.data}))
            }
        })
    }

    const loadStocks = () => {
        setLoading(true)
        dispatch(getProducts(state.payload))
        .then((response) => {
            const result = response.payload?.data?.data
            if(result) {
                setState((prevState) => ({...prevState, products:  result})) 
            }

            // if(response.payload?.next || response.payload?.previous) {
            //     const data = {
            //         next: response.payload?.next,
            //         prev: response.payload?.previous,
            //     }
            //     setpaginationData(data)
            // }
        })
    }

    const handleLogin = () => {
        if(invLogin) {
            loadAnalytics()
            return;
        }
        dispatch(loginCustomer({email: user?.email, password: user?.merchant_id}))
        .then((response) => {
            if(response.payload?.data) {
                loadAnalytics()
            }
        })
    }

    const handleCreateOrganisation = () => {
        // console.log("USER", user)
        // console.log("BUSINESS", business)
        dispatch(setupInventory({
            business_name:  user?.store_name,
            first_name: user?.first_name,
            last_name: user?.last_name,  
            email: user?.email,
            phone_number: user?.mobile,   
            wallx_id: user?.merchant_id,
            user_type: user?.user_type,
        }))
        .then((response) => {
            if(response.payload?.data) {
                handleLogin()
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
    }

    const handleSearch = () => {
        loadStocks()
    }

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            setState((prevState) => ({...prevState,  image: file, product_image: reader.result}))
        };
    }

    const sendRestockRequest = () => {
        setRequestStatus(true)
        dispatch(restock({product_id: state.product.id, reason: state.payload.reason,  quantity: state.payload.quantity}))
        .then((response) => {
            if(response.payload?.status === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                })
                .then(() => {
                    setState((prevState) => ({
                        ...prevState,
                        restockMode: false
                    }))
                })
            }
            else if(response.payload?.status === false) {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
        .finally(() => setRequestStatus(false))
    }

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <Col md={12} className="row align-items-center justify-content-between flex-wrapd">
                    <div className="col-md-4">
                        <h3 className="m-0">Inventory</h3>
                        <p>
                            <small>Manage stock level and restock</small>
                        </p>                        
                    </div>
                    <div className="col-md-8 text-right">
                        {/* <Button 
                            type="button" 
                            className="border-0 mb-2 px-3"
                            onClick={ () => setState((prevState) => ({...prevState, showStockForm: !state.showStockForm}))}
                        >
                            <FontAwesomeIcon icon={'plus'}/>
                            <span className="ps-2">Add Stock</span>
                        </Button> */}
                        <Button 
                            type="button" 
                            className="border-0 mb-2 px-3 ms-2"
                            onClick={ () => setState((prevState) => ({...prevState, 'show_vendor_form': !state.show_vendor_form}))}
                        >
                            <FontAwesomeIcon icon={'user-plus'}/>
                            <span className="ps-2">Add Supplier</span>
                        </Button>
                        <Button 
                            type="button" 
                            variant="primary" 
                            className="border-0 mb-2 ms-2"
                            onClick={ () => navigate('/members/stock-request')}
                        >
                            <FontAwesomeIcon icon={'box'} />
                            <span className="ps-2">Stock Request</span>
                        </Button>
                    </div>
                </Col>

                <div className="mt-4">
                    {/* {
                        loading ? (
                            <div className="page-loader">
                                <Spinner size="lg" variant="primary"/>
                            </div>
                        ) : null
                    } */}
                    <Row className="data-card mb-4">
                        <Col md={4} className="mb-2">
                            <Card className="border-0 shadow-sm bg--dark rounded-14">
                                <Card.Body className="align-items-center gap-3 p-3">
                                    <p className="text-muted font-bold">Stock Level</p>
                                    <div className="mb-2">
                                        <h3 className="font-bold">{formatCurrency(state.analytics?.no_of_unique_product || 0).formatted}</h3>
                                    </div>
                                    <span className="currency-wrapper">
                                        Overall total
                                    </span>
                                </Card.Body>
                            </Card> 
                        </Col>
                        <Col md={4} className="mb-2">
                            <Card className="border-0 shadow-sm bg--dark rounded-14">
                                <Card.Body className="align-items-center gap-3 p-3">
                                    <p className="text-muted font-bold">Total Instock</p>
                                    <div className="mb-2">
                                        <h3 className="font-bold">{ formatCurrency(state.analytics?.top_selling_items || 0).formatted }</h3>
                                    </div>
                                    <span className="currency-wrapper">
                                       Available
                                    </span>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className="mb-2">
                            <Card className="border-0 shadow-sm bg--dark rounded-14">
                                <Card.Body className="align-items-center gap-3 p-3">
                                    <p className="text-muted font-bold">Total Out of Stock</p>
                                    <div className="mb-2">
                                        <h3 className="font-bold">{ formatCurrency(state.analytics?.low_stock_item || 0).formatted }</h3>
                                    </div>
                                    <span className="currency-wrapper">
                                        Total sold
                                    </span>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mb-4 align-items-center justify-content-between">
                        <div className="col-md-5">
                            <h5 className="mb-2">Search Inventory</h5>
                        </div>
                        <div className="">
                            <InventoryFilter 
                                handleInput={handleInput}
                                handleSearchSubmit={handleSearch}
                            />
                        </div>
                    </Row>

                    <Card className="border-0 shadow-sm rounded-14">
                        <Card.Body className="table-responsive-lg">
                            <Table size="sm" className="table-color table-stripedd">
                                    <thead>
                                        <tr>
                                            <th>SKU</th>
                                            <th>Item Name</th>
                                            <th>Quantity</th>
                                            <th>Buying Price(₦)</th>
                                            <th>Selling Price(₦) <a href="#" className="text-primary"><small>Price list</small></a> </th>
                                            <th>Status</th>
                                            {/* <th></th> */}
                                        </tr>
                                    </thead>
                                    <tbody className="data-container">
                                        {
                                            loading ? (
                                                <div className="in-page-loader">
                                                    <Spinner size="lg" variant="primary"/>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            state.products?.length ? (
                                                state.products?.map((item, index) => (
                                                    <tr key={index}> 
                                                        <td>
                                                        <button 
                                                                type="button" 
                                                                className="btn btn-plain text-primary font-bold"
                                                                onClick={() => navigate('/members/inventory/stock-details/'+item.id)}
                                                            >
                                                                {item.sku}
                                                            </button> 
                                                        </td>
                                                        <td>{item.name}</td>
                                                        <td> {formatCurrency(item.quantity).formatted}  </td>
                                                        <td>{formatCurrency(item.price).with_currency} </td>
                                                        <td>{formatCurrency(item.sales_price).with_currency} </td>
                                                        <td>
                                                            <div className="d-flex m-0 align-items-center justify-content-between">
                                                                <Badge 
                                                                    className={item.quantity > item.restock_level ? 'badge-active' : 
                                                                    (item.quantity < item.restock_level ? 'badge-failed' : 
                                                                    (item.quantity === 0 ? 'badge-used' : 'badge-pending'))}
                                                                >
                                                                    <FontAwesomeIcon icon={'dot-circle'}
                                                                        color={
                                                                            item.quantity > item.restock_level ? "#008000" : 
                                                                            (item.quantity < item.restock_level ? "#EB5757" : 
                                                                            (item.quantity === 0 ? "#828282" : "#FAB941"))
                                                                        }
                                                                    />
                                                                    <span className="ps-1">
                                                                        {
                                                                            item.quantity > item.restock_level ? "In stock" : 
                                                                            (item.quantity < item.restock_level ? "Low stock" : 
                                                                            (item.quantity === 0 ? "Out of stock" : ""))
                                                                        }
                                                                    </span>
                                                                </Badge> 
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                        <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                    </Dropdown.Toggle>
        
                                                                    <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                        <Dropdown.Item 
                                                                            eventKey={1} 
                                                                            className="text-primary d-flex align-items-center gap-1" 
                                                                            onClick={() => manageProduct(item, 'view')}
                                                                        >
                                                                        <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Details
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Divider />
                                                                        <Dropdown.Item 
                                                                            eventKey={2} 
                                                                            className="text-primary d-flex align-items-center gap-1"
                                                                            onClick={(e) => manageProduct(item, 'restock')}
                                                                        >
                                                                            <i className="lni lni-question-circle"></i> Stock Request
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            )
                                            : 
                                            (
                                                <tr>
                                                    <td colSpan={5} className="text-center text-muted">No data found</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {
                        state.products?.length ? (
                            <div className="table-responsive mt-2">
                                <Paginator
                                    next={paginationData.next}
                                    prev={paginationData.prev}
                                    setCurrentPage={ handlePagination }
                                />
                            </div>
                        ) : null
                    }
                </div>

                <DrewsModal
                    show={state.showStockForm}
                    onHide={() => setState((prevState) => ({...prevState, showStockForm: false}))}
                    size="lg"
                    dismissable={true}
                    title={`Add Stock`}
                >
                    <Row>
                        <FormGroup as={Col} md={6} sm={12} className="mb-3">
                            <span> Product Name</span> <span className="text-danger">*</span>
                            <Form.Control
                                type="text"
                                name="product_name"
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                                placeholder="Enter your Product Name"
                                required
                            />
                            {
                                state.errors['product_name'] && 
                                <Form.Text className="text-danger">{state.errors['product_name']}</Form.Text>
                            }                            
                        </FormGroup>
                        <FormGroup as={Col} md={6} sm={12} className="mb-3">
                            <span>Sku/Code</span><span className="text-danger">*</span>
                            <Form.Control
                                type="text"
                                name="sku"
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                                placeholder="SKU or product code"
                                required
                            />
                            {
                                state.errors['sku'] && 
                                <Form.Text className="text-danger">{state.errors['sku']}</Form.Text>
                            }                           
                        </FormGroup>
                        <FormGroup as={Col} md={6} sm={12} className="mb-3">
                            <span>Quantity</span><span className="text-danger">*</span>
                            <Form.Control
                                type="number"
                                name="quantity"
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                                min={1}
                                placeholder="Quantity"
                                required
                            />
                            {
                                state.errors['quantity'] && 
                                <Form.Text className="text-danger">{state.errors['quantity']}</Form.Text>
                            }                           
                        </FormGroup>
                        <FormGroup as={Col} md={6} sm={12} className="mb-3">
                            <span>Product Unit</span>
                            <Form.Control
                                type="text"
                                name="unit"
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                                placeholder="kg, g"
                                required
                            />
                            {
                                state.errors['unit'] && 
                                <Form.Text className="text-danger">{state.errors['unit']}</Form.Text>
                            }                           
                        </FormGroup>
                        <FormGroup as={Col} md={6} sm={12} className="mb-3">
                            <span>Cost Price</span><span className="text-danger">*</span>
                            <Form.Control
                                type="number"
                                name="price"
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                                placeholder="Cost Price"
                                min={0}
                                required
                            />
                            {
                                state.errors['price'] && 
                                <Form.Text className="text-danger">{state.errors['price']}</Form.Text>
                            }                           
                        </FormGroup>
                        <FormGroup as={Col} md={6} sm={12} className="mb-3">
                            <span>Sales price</span><span className="text-danger">*</span>
                            <Form.Control
                                type="number"
                                name="sales_price"
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                                placeholder="Sales Price "
                                min={0}
                                required
                            />
                            {
                                state.errors['sales_price'] && 
                                <Form.Text className="text-danger">{state.errors['sales_price']}</Form.Text>
                            }                           
                        </FormGroup>
                        <FormGroup as={Col} md={6} sm={12} className="mb-3">
                            <span>Supply Date</span><span className="text-danger">*</span>
                            <Form.Control
                                type="date"
                                name="supply_date"
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                                required
                            />
                            {
                                state.errors['supply_date'] && 
                                <Form.Text className="text-danger">{state.errors['supply_date']}</Form.Text>
                            }                          
                        </FormGroup>
                        <FormGroup as={Col} md={6} sm={12} className="mb-3">
                            <span>Expiry Date</span><span className="text-danger">*</span>
                            <Form.Control
                                type="date"
                                name="expiry_date"
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                                required
                            />
                            {
                                state.errors['expiry_date'] && 
                                <Form.Text className="text-danger">{state.errors['expiry_date']}</Form.Text>
                            }                          
                        </FormGroup>
                        <FormGroup as={Col} md={6} sm={12} className="mb-3">
                            <span>Re-stock Level</span><span className="text-danger">*</span>
                            <Form.Control
                                type="number"
                                name="restock_level"
                                onChange={handleInput} 
                                onKeyUp={handleValidation}
                                placeholder="Restock level"
                                min={0}
                                required
                            />
                            {
                                state.errors['restock_level'] && 
                                <Form.Text className="text-danger">{state.errors['restock_level']}</Form.Text>
                            }                       
                        </FormGroup>

                        <FormGroup as={Col} md={6} sm={12} className="mb-3">
                            <span>Supplier</span> <span className="text-danger">*</span>
                            <Form.Select
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                                name="vendor_id"
                                required
                            >
                            <option value="">Select</option>
                                {state.suppliers?.map((supplier, i) => (
                                    <option value={supplier?.id} key={i}>
                                    {supplier?.name}
                                    </option>
                                ))}
                            </Form.Select>
                            {
                                state.errors['vendor_id'] && 
                                <Form.Text className="text-danger">{state.errors['vendor_id']}</Form.Text>
                            }                       
                        </FormGroup>

                        <FormGroup as={Col} md={12} sm={12} className="mb-3">
                            <span>Description</span><span className="text-danger">*</span>
                            <textarea
                                name="description"
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                                placeholder="Product description"
                                className="form-control"
                                required
                            ></textarea>
                            {
                                state.errors['description'] && 
                                <Form.Text className="text-danger">{state.errors['description']}</Form.Text>
                            }                       
                        </FormGroup>
                        <FormGroup as={Col} md={12} sm={12} className="mb-3">
                            <span>Item Images</span><span className="text-danger">*</span>
                            <Form.Control
                                type="file"
                                onChange={handleFileSelect}
                                name="image"
                                accept="image/*"
                                multiple
                            />
                            <div className="mt-2 mb-2">
                                {
                                    state.product_image ? (
                                        <img
                                            src={state.product_image}
                                            alt="Product Image"
                                            width="100"
                                            height="100"
                                            className="img-thumbnail"
                                        />
                                    ) : null
                                }
                            </div>
                        </FormGroup>
                        <FormGroup as={Col} md={12} sm={12}>
                            <div className="d-grid">
                                <Button variant="primary" type="submit" onClick={addStock}>
                                    Submit <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </div>
                        </FormGroup>
                    </Row>
                </DrewsModal>

                <DrewsModal
                    show={state.show_vendor_form}
                    onHide={() => setState((prevState) => ({...prevState, show_vendor_form: false}))}
                    size="md"
                    dismissable={true}
                    title="Add Supplier"
                >
                    <Form>
                        <Row>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0">Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Vendor name"
                                    name="name"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    state.errors['name'] && 
                                    <Form.Text className="text-danger">{state.errors['name']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0">Phone Number</Form.Label>
                                <Form.Control
                                    type="tel"
                                    required
                                    placeholder="Phone Number"
                                    name="phone_number"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    minLength={11}
                                    maxLength={11}
                                />
                                {
                                    state.errors['phone_number'] && 
                                    <Form.Text className="text-danger">{state.errors['phone_number']}</Form.Text>
                                }
                            </Form.Group>
                            
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0">Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    required
                                    placeholder="Email"
                                    name="email"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    state.errors['email'] && 
                                    <Form.Text className="text-danger">{state.errors['email']}</Form.Text>
                                }
                            </Form.Group>
                            
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0">Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Address"
                                    name="address"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    state.errors['address'] && 
                                    <Form.Text className="text-danger">{state.errors['address']}</Form.Text>
                                }
                            </Form.Group>
                            
                            <Form.Group 
                                as={Col} 
                                md={12} 
                                className="mt-4 d-grid"
                            >
                                <Button 
                                    variant="primary" 
                                    size="lg" 
                                    onClick={createVendor}
                                    disabled={Object.keys(state.errors).length > 0}
                                >
                                    Submit <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>

                <DrewsModal
                    show={state.restockMode}
                    onHide={() => setState((prevState) => ({...prevState, restockMode: false}))}
                    size="md"
                    dismissable={true}
                    title={`${state.product.sku} - ${state.product.name}`}
                >
                    <Row>
                        <FormGroup as={Col} md={12} sm={12} className="mb-3">
                            <span> Quantity</span> <span className="text-danger">*</span>
                            <Form.Control
                                type="number"
                                name="quantity"
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                                placeholder="Enter your Product quantity"
                                required
                                min={1}
                            />
                            {
                                state.errors['quantity'] && 
                                <Form.Text className="text-danger">{state.errors['quantity']}</Form.Text>
                            }                            
                        </FormGroup>

                        <FormGroup as={Col} md={12} sm={12} className="mb-3">
                            <span>Reason for restock</span><span className="text-danger">*</span>
                            <textarea
                                name="reason"
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                                placeholder="Reasons for product restock"
                                className="form-control"
                                required
                            ></textarea>
                            {
                                state.errors['reason'] && 
                                <Form.Text className="text-danger">{state.errors['reason']}</Form.Text>
                            }                       
                        </FormGroup>
                        <FormGroup as={Col} md={12} sm={12}>
                            <div className="d-grid">
                                <Button variant="primary" type="submit" onClick={sendRestockRequest}>
                                    Submit <Spinner animation={ request_status ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </div>
                        </FormGroup>
                    </Row>
                </DrewsModal>
            </Container>
        </Layout>
    );
}

export default StockInventory;
import { Row, Col, Container, Card, Button, Form, InputGroup, Spinner, FormGroup, ListGroup, Collapse } from "react-bootstrap";
import Layout from "../components/Layout";
import { useEffect, useState } from "react";
import DrewsModal from "../components/modal/DrewsModal";
import { useDispatch } from "react-redux";
import { getBeneficiaries, addBeneficiary, getMerchants, removeBeneficiary, makeTransfer, getMerchantById } from "../redux/slices/p2p.slice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import formatCurrency from "../utils/currencyFormmter";

const P2pPayment = () => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [showForm, setTransfer] = useState(false)
    const [payload, setPayload] = useState({})
    const [loading, setLoading] = useState(false)
    const [beneficiaries, setBeneficaries] = useState([])
    const [merchant_list, setMerchants] = useState([])
    const [merchant, setMerchant] = useState({})
    const [searchParams, setSearchParams] = useSearchParams()
    const [errors, setErrors] = useState({})
    const [is_beneficiary, setIsBeneficiary] = useState(false)
    const [show_beneficiaries, setShowbeneficiaries] = useState(false)

    useEffect(() => {
        loadBeneficiaries()
        if(searchParams.get('merchant_id')) {
            findMerchant(searchParams.get('merchant_id'))
        }
    }, []) 

    const handleInput = (event) => {
        const { name, value } = event.target;
        if(name === 'save_beneficiary') {
            let val = event.target.checked ? 'yes' : 'no'
            setPayload((prevState) => ({...prevState, [name]: val}))
        }
        else if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^\d.]/g, ''))
            setPayload((prevState)=> ({...prevState,[name]:newValue}))
        }
        else {
            setPayload((prevState) => ({...prevState, [name]: value}))
        }
    }

    const handleValidation = (event) => {

        const { name, value } = event.target

        const regex = /^\d*\.?\d*$/;

        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if (name ==='amount' && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }
        // console.log(payload)
    }

    const searchMerchants = () => {
        setLoading(true)
        dispatch(getMerchants(payload.merchant_id))
        .then((e) => {
            if(typeof(e.payload) === 'object') {
                setMerchants(e.payload)
            }
            if(e.payload?.success === false) {
                Swal.fire({
                    title: 'Error!',
                    text: e.payload.message,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const transfer = () => {
        if(!payload.amount && !payload.secret_word) { return }

        let request_payload = payload
        request_payload.merchant_id = merchant.merchant_id

        setLoading(true)
        dispatch(makeTransfer(request_payload))
        .then((r) => {
            if(r.payload?.success !== 'False'){
                setTransfer(false)
                if(payload.save_beneficiary) {
                    createBeneficiary(merchant.merchant_id)
                }
                Swal.fire({
                    icon: 'success',
                    text: r.payload.message
                })
                .then(() => {
                    window.location.replace(window.location.origin+window.location.pathname)
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    text: r.payload.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const findMerchant = (id) => {
        setLoading(true)
        dispatch(getMerchantById(id))
        .then((res) => {  
            if(res.payload){
                setMerchant(res.payload)
            }
        })
        .finally(() => setLoading(false))
    }

    const loadBeneficiaries = () => {
        dispatch(getBeneficiaries())
        .then((b) => {
            if(typeof(b.payload) === 'object') {
                setBeneficaries(b.payload)
            }
        })
    }

    const createBeneficiary = (id) => {
        dispatch(addBeneficiary(id))
        .then(() => loadBeneficiaries())
    }

    const selectMerchant = (merchant) => {
        setMerchant(merchant)
        // setTransfer(true)
        let exists = beneficiaries?.filter(b => b.merchant_id === merchant?.merchant_id).length ? true : false
        setIsBeneficiary(exists)

        findMerchant(merchant.merchant_id)
    }

    const deleteBeneficiary = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#443792',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes! Proceed'
        })
        .then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                dispatch(removeBeneficiary(id))
                .then((res) => {
                    if(res.payload?.success === true) {
                        Swal.fire({
                            icon: 'success',
                            text: res.payload?.message
                        })
                        .then(() => {
                            loadBeneficiaries()
                        })
                    }
                    else if(res.payload?.success === 'False') {
                        Swal.fire({
                            icon: 'error',
                            text: res.payload?.message
                        })
                    }
                })
                .finally(() => setLoading(false))
            }
        })
    }

    return (
        <Layout>
            <Container className="mt-5 px-4 mb-4">
                <Row className="justify-content-center"> 
                {
                    merchant?.store_name ? (
                        <Col md={11} lg={8} sm={11}>
                            <Card className="border-0 shadow-sm rounded-3">
                                <Card.Body>
                                    <h5>{merchant?.store_name}</h5><hr/>
                                    <div className="d-flex justify-content-start mb-4">
                                        <img 
                                            src={merchant?.profile_image || 'https://via.placeholder.com/100'} 
                                            alt={merchant?.store_name} 
                                            width="100" 
                                            height="100"
                                        />
                                        <div className="ms-2">
                                            <p className="mb-1"><strong>ID:</strong> {merchant?.merchant_id}</p>
                                            <p className="mb-1"><strong>Type:</strong> {merchant?.business_type}</p>
                                            <p className="mb-1"><strong>Phone Number:</strong> {merchant?.phone_number}</p>
                                            <p className="mb-1"><strong>Address:</strong> {merchant?.store_address}</p>
                                            <p className="mb-1"><strong>About Business:</strong> {merchant?.store_about}</p>
                                            <p className="mb-1"><strong>Status:</strong> <strong className={merchant?.is_verified ? 'text-success' : 'text-primary'}>
                                                {merchant?.is_verified ? 'Verified' : 'Not Veirifed' }</strong> 
                                            </p>
                                        </div>
                                    </div>

                                    <div>
                                        <h5>Beneficiaries</h5>
                                        {
                                            beneficiaries?.length ? (
                                                beneficiaries?.map((user, index) => (
                                                    <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center mb-2">
                                                        <span>{index+1}. {user.business_name} </span> 
                                                        <Button 
                                                            type="button" 
                                                            variant="light" 
                                                            className="border-0 bg-danger text-white" 
                                                            size="sm"
                                                            onClick={ () => deleteBeneficiary(user.merchant_id) }
                                                        >
                                                            Remove <Spinner animation={ loading ? "border" : null} variant="white" role="status" size="sm"></Spinner>
                                                        </Button>
                                                    </ListGroup.Item>
                                                ))
                                            ) : null
                                        }
                                    </div>
                                </Card.Body>
                                <Card.Footer className="d-flex justify-content-between">
                                    <Button 
                                        variant="outline-primary"  
                                        size="sm" 
                                        onClick={() =>  window.location.replace(window.location.origin+window.location.pathname)}
                                    >
                                        <FontAwesomeIcon icon="arrow-left-long" /> Cancel
                                    </Button>
                                    <Button 
                                        type="button" 
                                        size="sm" 
                                        variant="primary"
                                        onClick={ () => setTransfer(true)}
                                    >
                                        Pay {merchant?.store_name}
                                    </Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    ) 
                    : 
                    (
                        <Col md={11} lg={8} sm={11}>
                            <Card className="border-0 shadow-sm rounded-16">
                                <Card.Body className="py-4 pe-3 ps-3">
                                    <div className="text-center">
                                        <h4>P2P Payment</h4>
                                        <p>Send money to a merchant/vendor</p>
                                    </div>
                                    <div className="border border-1 p-3 rounded mb-5 mt-5">
                                        <h5>Recent beneficiaries</h5>
                                        {
                                            beneficiaries?.length ? (
                                                <div className="mt-3 d-flex gap-2 align-content-center wrap">
                                                    {
                                                        beneficiaries?.map((user, index) => (
                                                            <div className="text-center mx-2" key={index} onClick={ () => selectMerchant(user) }>
                                                                <img src={user.profile_photo || 'https://via.placeholder.com/100'} alt={user.business_name}  className="profile-photo" />
                                                                <p className="text-muted text-sm">{user.business_name}</p>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            ) : null
                                        }
                                        <div className="text-right">
                                            <Button 
                                                type="button" 
                                                variant="light" 
                                                className="border-0 bg-transparent" 
                                                size="sm"
                                                onClick={ () => setShowbeneficiaries(!show_beneficiaries) }
                                            >
                                                View all
                                            </Button>
                                        </div>
                                        <Collapse in={show_beneficiaries} className="mt-3">
                                            <div>
                                                <h5>Beneficiaries</h5>
                                                {
                                                    beneficiaries?.length ? (
                                                        beneficiaries?.map((user, index) => (
                                                            <ListGroup.Item  key={index} className="d-flex justify-content-between align-items-center mb-2">
                                                               <span>{index+1}. {user.business_name} </span> 
                                                                <Button 
                                                                    type="button" 
                                                                    variant="light" 
                                                                    className="border-0 bg-danger text-white" 
                                                                    size="sm"
                                                                    onClick={ () => deleteBeneficiary(user.merchant_id) }
                                                                >
                                                                    Remove <Spinner animation={ loading ? "border" : null} variant="white" role="status" size="sm"></Spinner>
                                                                </Button>
                                                            </ListGroup.Item>
                                                        ))
                                                    ) : null
                                                }
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="border border-1 p-3 rounded pt-5">
                                        <Form>
                                            <Row>
                                                <Form.Group as={Col} md={12}>
                                                    <Form.Label>Search by merchant ID</Form.Label>
                                                    <InputGroup hasValidation className="custom-search-box">
                                                        <InputGroup.Text id="inputGroupPrepend" className="text-muted border-0">
                                                            <Button type="button" variant="light" className="border-0 input-group-btn-bg">
                                                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path d="M16.6725 16.6412L21 21M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg> */}
                                                                <FontAwesomeIcon icon={'search'} fontSize={20} />
                                                            </Button>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Search for Merchant ID"
                                                            aria-describedby="inputGroupPrepend"
                                                            required
                                                            size="lg"
                                                            name="merchant_id"
                                                            onChange={handleInput}
                                                        />
                                                    </InputGroup>
                                                    <div className="mt-1">
                                                        {
                                                            merchant_list?.length && typeof(merchant_list) === 'object' ? 
                                                            (
                                                                merchant_list.map((merchant, index) => (
                                                                    <div className="d-flex justify-content-start" key={index}>
                                                                        <ListGroup.Item action onClick={ () => selectMerchant(merchant)}  className="text-primary">
                                                                            <img 
                                                                                src={merchant?.photo || 'https://via.placeholder.com/100'} 
                                                                                alt={merchant?.business_name} 
                                                                                width="50" 
                                                                                height="50"
                                                                                className="rounded-circle"
                                                                            />
                                                                            <span className="ms-2">{merchant.business_name}</span>
                                                                        </ListGroup.Item>
                                                                    </div>
                                                                ))
                                                            ) : null
                                                        }
                                                    </div>
                                                </Form.Group>
                                                <Form.Group as={Col} md={12} className="mt-4">
                                                    <Button 
                                                        type="button" 
                                                        variant="primary" 
                                                        size="lg" 
                                                        className="form-control"
                                                        onClick={searchMerchants}
                                                    >
                                                        Search <Spinner animation={ loading ? "border" : null} variant="light" role="status" size="sm"></Spinner>
                                                    </Button>
                                                </Form.Group>
                                            </Row>
                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                }
                </Row>

                <DrewsModal
                    show={showForm}
                    onHide={() => setTransfer(false)}
                    size="md"
                    dismissable={true}
                    title={`P2P Transfer`}
                >
                    <Form noValidate>
                        <Row>
                            <FormGroup as={Col} md={12}>
                                <Form.Label>Amount</Form.Label>
                                <Form.Control 
                                    type="text"
                                    name="amount"
                                    placeholder="0.00"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}
                                />
                                {
                                    errors['amount'] ? 
                                    <Form.Text className="text-danger">{errors['amount']}</Form.Text> : null
                                }
                            </FormGroup>
                            <FormGroup as={Col} md={12} className="mt-3">
                                <Form.Label>Secret word</Form.Label>
                                <Form.Control 
                                    type="password"
                                    name="secret_word"
                                    placeholder="Secret word"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    required
                                />
                                {
                                    errors['secret_word'] ? 
                                    <Form.Text className="text-danger">{errors['secret_word']}</Form.Text> : null
                                }
                            </FormGroup>
                            {
                                !is_beneficiary ? (
                                    <FormGroup as={Col} md={12} className="mt-3">
                                        <Form.Check
                                            label="Save Beneficiary"
                                            name="save_beneficiary"
                                            value="yes"
                                            onChange={handleInput}
                                        />
                                    </FormGroup>
                                ) : null
                            }
                            <FormGroup as={Col} md={12} className="mt-3">
                                <Button 
                                    type="button" 
                                    variant="primary"
                                    className="form-control"
                                    onClick={transfer} 
                                    disabled={Object.keys(errors).length > 0}
                                >
                                    Send <Spinner animation={ loading ? "border" : null} variant="light" role="status" size="sm"></Spinner>
                                </Button>
                            </FormGroup>
                        </Row>
                    </Form>
                </DrewsModal>

            </Container>
        </Layout>
    );
}

export default P2pPayment;
export const menus = [
    {
        "name": "Dashboard",
        "url": "/dashboard",
        "icon": "lni lni-grid-alt",
        "icon_type": "lni",
        "alias": "dashboard",
        "auth_required": true,
        "user_type": "manager",
    },
    {
        "name": "Transactions History",
        "url": "/transactions",
        "icon": "fa fa-rotate-left",
        "icon_type": "fa",
        "alias": "transactions",
        "auth_required": true,
        "user_type": "manager",
    },
    {
        "name": "Reports",
        "url": "/reports",
        "icon": "fa fa-chart-pie",
        "icon_type": "fa",
        "alias": "reports",
        "auth_required": true,
        "user_type": "manager",
    },
    {
        "name": "Support & Ticketing",
        "url": "/tickets",
        "icon": "fa fa-headset",
        "icon_type": "fa",
        "alias": "support_tickets",
        "auth_required": true,
        "user_type": "manager",
    },
    {
        "name": "Rewards",
        "url": "/rewards",
        "icon": "fa fa-gift",
        "icon_type": "fa",
        "alias": "rewards",
        "auth_required": true,
        "user_type": "manager",
    },
    {
        "name": "DIGITAL BANKING",
        "children": [
            {
                "name": "Paycodes Payment",
                "url": "/paycodes",
                "icon": "fa fa-money-check",
                "icon_type": "fa",
                "alias": "paycodes",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Invoice",
                "url": "/invoices",
                "icon": "fa fa-receipt",
                "icon_type": "fa",
                "alias": "invoices",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "P2P",
                "url": "/p2p-payment",
                "icon": "fa fa-arrow-right-arrow-left",
                "icon_type": "fa",
                "alias": "p2p",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Bills Payment",
                "url": "/bills-payment",
                "icon": "fa fa-receipt",
                "icon_type": "fa",
                "alias": "bills_payment",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Wallets",
                "url": "/wallets",
                "icon": "fas fa-wallet",
                "icon_type": "fa",
                "alias": "wallets",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Subscriptions",
                "url": "/subscriptions",
                "icon": "fas fa-box-open",
                "icon_type": "fa",
                "alias": "subscriptions",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "FaasPay",
                "url": "/fasepay",
                "icon": "fas fa-credit-card",
                "icon_type": "fa",
                "alias": "faspay",
                "auth_required": true,
                "user_type": "manager",
                "is_new": true,
            },
            {
                "name": "Loyalty Voucher",
                "url": "/loyalty-voucher",
                "icon": "gifts",
                "icon_type": "fa",
                "alias": "loyalty-voucher",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Tax Calculator",
                "url": "/tax-calculator",
                "icon": "calculator",
                "icon_type": "fa",
                "alias": "tax-calculator",
                "auth_required": true,
                "user_type": "manager",
            },
        ]
    },
    {
        "name": "CUSTOMER MARKETING",
        "children": [
            {
                "name": "Customers",
                "url": "/customers",
                "icon": "lni lni-users",
                "icon_type": "lni",
                "alias": "customers",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "SMS Marketing",
                "url": "/messaging/sms",
                "icon": "fa fa-comment",
                "icon_type": "fa",
                "alias": "sms",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Email Marketing",
                "url": "/messaging",
                "icon": "lni lni-envelope",
                "icon_type": "lni",
                "alias": "email",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Customer's Rating",
                "url": "/ratings",
                "icon": "fa fa-star",
                "icon_type": "fa",
                "alias": "ratings",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Business Inco. request",
                "url": "/business-request",
                "icon": "lni lni-users",
                "icon_type": "lni",
                "alias": "business_request",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Loan Request",
                "url": "/loan-request",
                "icon": "fa fa-bank",
                "icon_type": "lni",
                "alias": "loan_request",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Announcements",
                "url": "/announcements",
                "icon": "fa fa-bullhorn",
                "icon_type": "fa",
                "alias": "announcements",
                "auth_required": true,
                "user_type": "manager",
            },
        ]
    },
    {
        "name": "INVENTORY",
        "children": [
            {
                "name": "Stock",
                "url": "/inventory",
                "icon": "fa fa-shop",
                "icon_type": "fa",
                "alias": "inventory",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Stock Request",
                "url": "/stock-request",
                "icon": "fa fa-box",
                "icon_type": "fa",
                "alias": "stock_request",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Price List",
                "url": "/inventory/pricelist",
                "icon": "fa fa-list",
                "icon_type": "fa",
                "alias": "pricelist",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Sales Tracker",
                "url": "/inventory/sales-tracker",
                "icon": "fa fa-chart-line",
                "icon_type": "fa",
                "alias": "seales-tracker",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Suppliers",
                "url": "/inventory/vendors",
                "icon": "fa fa-truck",
                "icon_type": "fa",
                "alias": "vendor-list",
                "auth_required": true,
                "user_type": "manager",
            },
        ]
    },
    {
        "name": "SETTINGS",
        "children": [
            {
                "name": "Settings",
                "url": "/settings",
                "icon": "lni lni-cog",
                "icon_type": "lni",
                "alias": "settings",
                "auth_required": true,
                "user_type": "manager",
            },
            {
                "name": "Managers",
                "url": "/managers",
                "icon": "lni lni-user",
                "icon_type": "lni",
                "alias": "managers",
                "auth_required": true,
                "user_type": "manager",
            },
        ]
    },
]
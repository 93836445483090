import { Row, Col, Container, Card, Button, Table, Badge, Dropdown, Form, Spinner } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../components/table/Paginator";
import { useState, useEffect } from "react";
import avatar from '../../assets/images/avatar.svg';
import DrewsModal from "../../components/modal/DrewsModal";
import { useDispatch, useSelector } from "react-redux";
import { getUserVouchers, getPaycodes, createBulkPaycode } from "../../redux/slices/account.service";
import { clearMessage } from "../../redux/slices/message";
import formatCurrency from "../../utils/currencyFormmter";
import Swal from "sweetalert2";
import dateFormatter from "../../utils/dateFormatter";

const Voucher = () => {

    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.auth)

    const [modalShow, setModalShow] = useState(false)
    const [showForm, setForm] = useState(false)
    const [show, setShow] = useState(false)
    const [showBalance, setShowBalance] = useState(false)
    const [errors, setErrors] = useState({})
    const [alertMessage, setAlertMessage] = useState(null)
    const [vouchers, setVouchers] = useState([])
    const [transaction, setTransaction] = useState({})
    const [loading, setLoading] = useState(false)
    const [payload, setPayload] = useState({})

    const { message } = useSelector((state) => state.message)

    useEffect(() => {
        loadVouchers()
    }, [])

    useEffect(() => {
        if (message && message?.status === 'True') {
            setShowBalance(false)
            Swal.fire({
                icon: 'success',
                text: message.message
            })
        }
        else if(message && message?.status === 'False') {
            Swal.fire({
                icon: 'error',
                text: message.message
            })
        }
        dispatch(clearMessage())
    }, [message, dispatch])

    const selectTransaction = (id) => {
        setModalShow(true)
        setTransaction(vouchers.filter(item => item.id === id)[0])
    }

    const handleInput = (event) => {
        const {name, value} = event.target
        if(name === 'dob') {
            const dateParts = value.split('-');
            const dob = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
            setPayload((prevState) => ({...prevState, [name]: dob}))
        }
        else if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^\d.]/g, ''))
            setPayload((prevState)=> ({...prevState,[name]:newValue}))
        }
        else {
            setPayload((prevState) => ({...prevState, [name]: value}))
        }
        
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else if (name ==='amount' && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }
    }


    const loadPaycodes = () => {
        dispatch(getPaycodes())
        .then((response) => {
            if(response.payload.results) {
                setVouchers(response.payload.results)
            }
        })
    }

    const createPayCode = () => {
        setLoading(true)
        dispatch(createBulkPaycode(payload))
        .then((e) => {
            loadVouchers()
        })
        .finally(() => setLoading(false))
    }

    const loadVouchers = () => {
        dispatch(getUserVouchers())
        .then((response) => {
            if(response.payload.results) {
                setVouchers(response.payload.results)
            }
        })
    }

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <Col md={12} className="d-flex align-items-center justify-content-between">
                    <h3>Used/Expired Vouchers</h3>
                    <Button 
                        type="button" 
                        variant="primary" 
                        onClick={ () => setShowBalance(true)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M7.0498 7.0498H7.0598M10.5118 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V10.5118C3 11.2455 3 11.6124 3.08289 11.9577C3.15638 12.2638 3.27759 12.5564 3.44208 12.8249C3.6276 13.1276 3.88703 13.387 4.40589 13.9059L9.10589 18.6059C10.2939 19.7939 10.888 20.388 11.5729 20.6105C12.1755 20.8063 12.8245 20.8063 13.4271 20.6105C14.112 20.388 14.7061 19.7939 15.8941 18.6059L18.6059 15.8941C19.7939 14.7061 20.388 14.112 20.6105 13.4271C20.8063 12.8245 20.8063 12.1755 20.6105 11.5729C20.388 10.888 19.7939 10.2939 18.6059 9.10589L13.9059 4.40589C13.387 3.88703 13.1276 3.6276 12.8249 3.44208C12.5564 3.27759 12.2638 3.15638 11.9577 3.08289C11.6124 3 11.2455 3 10.5118 3ZM7.5498 7.0498C7.5498 7.32595 7.32595 7.5498 7.0498 7.5498C6.77366 7.5498 6.5498 7.32595 6.5498 7.0498C6.5498 6.77366 6.77366 6.5498 7.0498 6.5498C7.32595 6.5498 7.5498 6.77366 7.5498 7.0498Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span className="ps-2">Generate Voucher</span>
                    </Button>
                </Col>

                <div className="mt-4">
                    {/* <Row className="data-card mb-4">
                        <Col md={4}>
                            <Card className="border-0 shadow-sm bg--light">
                                <Card.Body className="d-flex align-items-center gap-3 p-3">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <path d="M11.25 8.62516C11.25 8.27999 10.9702 8.00016 10.625 8.00016H5.625C5.27982 8.00016 5 8.27999 5 8.62516C5 8.97034 5.27982 9.25016 5.625 9.25016H10.625C10.9702 9.25016 11.25 8.97034 11.25 8.62516Z" fill="#4F4F4F"/>
                                            <path d="M10.4167 11.1252C10.4167 10.78 10.1368 10.5002 9.79167 10.5002H5.625C5.27982 10.5002 5 10.78 5 11.1252C5 11.4703 5.27982 11.7502 5.625 11.7502H9.79167C10.1368 11.7502 10.4167 11.4703 10.4167 11.1252Z" fill="#4F4F4F"/>
                                            <path d="M10.625 13.0002C10.9702 13.0002 11.25 13.28 11.25 13.6252C11.25 13.9703 10.9702 14.2502 10.625 14.2502H5.625C5.27982 14.2502 5 13.9703 5 13.6252C5 13.28 5.27982 13.0002 5.625 13.0002H10.625Z" fill="#4F4F4F"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 18.6252H15.8333C17.099 18.6252 18.125 17.5992 18.125 16.3335V11.7502C18.125 11.405 17.8452 11.1252 17.5 11.1252H14.7917V4.6195C14.7917 3.43333 13.4509 2.74335 12.4857 3.4328L12.3398 3.537C11.6894 4.00157 10.8084 4.00001 10.1549 3.53325C9.06839 2.75714 7.59828 2.75714 6.51173 3.53325C5.85826 4.00001 4.97725 4.00157 4.32685 3.537L4.18097 3.4328C3.21575 2.74335 1.875 3.43333 1.875 4.6195V15.5002C1.875 17.2261 3.27411 18.6252 5 18.6252ZM7.23827 4.55042C7.89021 4.08476 8.77646 4.08476 9.42839 4.55042C10.5134 5.32544 11.9783 5.33136 13.0664 4.55416L13.2122 4.44997C13.3501 4.35148 13.5417 4.45004 13.5417 4.6195V16.3335C13.5417 16.7086 13.6318 17.0626 13.7915 17.3752H5C3.96447 17.3752 3.125 16.5357 3.125 15.5002V4.6195C3.125 4.45004 3.31654 4.35148 3.45442 4.44997L3.6003 4.55416C4.68838 5.33136 6.15325 5.32544 7.23827 4.55042ZM14.7917 16.3335V12.3752H16.875V16.3335C16.875 16.9088 16.4086 17.3752 15.8333 17.3752C15.258 17.3752 14.7917 16.9088 14.7917 16.3335Z" fill="#4F4F4F"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <h5 className="m-0">05</h5>
                                        <p className="m-0">All vouchers generated</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="border-0 shadow-sm bg--success">
                                <Card.Body className="d-flex align-items-center gap-3 p-3">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <path d="M11.25 8.62516C11.25 8.27999 10.9702 8.00016 10.625 8.00016H5.625C5.27982 8.00016 5 8.27999 5 8.62516C5 8.97034 5.27982 9.25016 5.625 9.25016H10.625C10.9702 9.25016 11.25 8.97034 11.25 8.62516Z" fill="#008000"/>
                                            <path d="M10.4167 11.1252C10.4167 10.78 10.1368 10.5002 9.79167 10.5002H5.625C5.27982 10.5002 5 10.78 5 11.1252C5 11.4703 5.27982 11.7502 5.625 11.7502H9.79167C10.1368 11.7502 10.4167 11.4703 10.4167 11.1252Z" fill="#008000"/>
                                            <path d="M10.625 13.0002C10.9702 13.0002 11.25 13.28 11.25 13.6252C11.25 13.9703 10.9702 14.2502 10.625 14.2502H5.625C5.27982 14.2502 5 13.9703 5 13.6252C5 13.28 5.27982 13.0002 5.625 13.0002H10.625Z" fill="#008000"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 18.6252H15.8333C17.099 18.6252 18.125 17.5992 18.125 16.3335V11.7502C18.125 11.405 17.8452 11.1252 17.5 11.1252H14.7917V4.6195C14.7917 3.43333 13.4509 2.74335 12.4857 3.4328L12.3398 3.537C11.6894 4.00157 10.8084 4.00001 10.1549 3.53325C9.06839 2.75714 7.59828 2.75714 6.51173 3.53325C5.85826 4.00001 4.97725 4.00157 4.32685 3.537L4.18097 3.4328C3.21575 2.74335 1.875 3.43333 1.875 4.6195V15.5002C1.875 17.2261 3.27411 18.6252 5 18.6252ZM7.23827 4.55042C7.89021 4.08476 8.77646 4.08476 9.42839 4.55042C10.5134 5.32544 11.9783 5.33136 13.0664 4.55416L13.2122 4.44997C13.3501 4.35148 13.5417 4.45004 13.5417 4.6195V16.3335C13.5417 16.7086 13.6318 17.0626 13.7915 17.3752H5C3.96447 17.3752 3.125 16.5357 3.125 15.5002V4.6195C3.125 4.45004 3.31654 4.35148 3.45442 4.44997L3.6003 4.55416C4.68838 5.33136 6.15325 5.32544 7.23827 4.55042ZM14.7917 16.3335V12.3752H16.875V16.3335C16.875 16.9088 16.4086 17.3752 15.8333 17.3752C15.258 17.3752 14.7917 16.9088 14.7917 16.3335Z" fill="#008000"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <h5 className="m-0">05</h5>
                                        <p className="m-0">All Active PayCodes</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="border-0 shadow-sm bg--warning">
                                <Card.Body className="d-flex align-items-center gap-3 p-3">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <path d="M11.25 8.62516C11.25 8.27999 10.9702 8.00016 10.625 8.00016H5.625C5.27982 8.00016 5 8.27999 5 8.62516C5 8.97034 5.27982 9.25016 5.625 9.25016H10.625C10.9702 9.25016 11.25 8.97034 11.25 8.62516Z" fill="#F2994A"/>
                                            <path d="M10.4167 11.1252C10.4167 10.78 10.1368 10.5002 9.79167 10.5002H5.625C5.27982 10.5002 5 10.78 5 11.1252C5 11.4703 5.27982 11.7502 5.625 11.7502H9.79167C10.1368 11.7502 10.4167 11.4703 10.4167 11.1252Z" fill="#F2994A"/>
                                            <path d="M10.625 13.0002C10.9702 13.0002 11.25 13.28 11.25 13.6252C11.25 13.9703 10.9702 14.2502 10.625 14.2502H5.625C5.27982 14.2502 5 13.9703 5 13.6252C5 13.28 5.27982 13.0002 5.625 13.0002H10.625Z" fill="#F2994A"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 18.6252H15.8333C17.099 18.6252 18.125 17.5992 18.125 16.3335V11.7502C18.125 11.405 17.8452 11.1252 17.5 11.1252H14.7917V4.6195C14.7917 3.43333 13.4509 2.74335 12.4857 3.4328L12.3398 3.537C11.6894 4.00157 10.8084 4.00001 10.1549 3.53325C9.06839 2.75714 7.59828 2.75714 6.51173 3.53325C5.85826 4.00001 4.97725 4.00157 4.32685 3.537L4.18097 3.4328C3.21575 2.74335 1.875 3.43333 1.875 4.6195V15.5002C1.875 17.2261 3.27411 18.6252 5 18.6252ZM7.23827 4.55042C7.89021 4.08476 8.77646 4.08476 9.42839 4.55042C10.5134 5.32544 11.9783 5.33136 13.0664 4.55416L13.2122 4.44997C13.3501 4.35148 13.5417 4.45004 13.5417 4.6195V16.3335C13.5417 16.7086 13.6318 17.0626 13.7915 17.3752H5C3.96447 17.3752 3.125 16.5357 3.125 15.5002V4.6195C3.125 4.45004 3.31654 4.35148 3.45442 4.44997L3.6003 4.55416C4.68838 5.33136 6.15325 5.32544 7.23827 4.55042ZM14.7917 16.3335V12.3752H16.875V16.3335C16.875 16.9088 16.4086 17.3752 15.8333 17.3752C15.258 17.3752 14.7917 16.9088 14.7917 16.3335Z" fill="#F2994A"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <h5 className="m-0">00</h5>
                                        <p className="m-0">Used PayCodes</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row> */}

                    <Row className="mb-4">
                        <Form.Group as={Col} md={6}>
                            <Form.Control
                                type="search"
                                placeholder="Search by ID"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={2}>
                            <div className="filter-button gap-1">
                                <span>Filter by:</span>
                                <Form.Select
                                    required
                                    name="sort"
                                >
                                    <option value="">None</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} md={2}>
                            <div className="filter-button gap-1">
                                <span>Sort by:</span>
                                <Form.Select
                                    required
                                    name="sort"
                                >
                                    <option value="">None</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} md={2}>
                            <Button type="button" variant="light" className="bg-white text-primary">
                                <FontAwesomeIcon icon="fa fa-share-nodes" style={{fontSize: '17px'}} />
                                <span className="px-2">Export sheet</span>
                            </Button>
                        </Form.Group>
                    </Row>

                    <Card className="border-0 shadow-sm">
                        <Card.Body className="table-responsive-lg">
                            <Table size="sm" className="table-color">
                                <thead>
                                    <tr>
                                        <th>Reference ID</th>
                                        <th>Customer</th>
                                        <th>Amt Generated</th>
                                        <th>Amt Remaining</th>
                                        <th>Date Created</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        vouchers?.length ? (
                                            vouchers.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {item.reference}
                                                    </td>
                                                    <td>
                                                        <p className="m-0 d-flex gap-2 align-items-center">
                                                            <img src={item.avatar || 'https://via.placeholder.com/50'} alt="avatar" width="30" className="rounded-circle"/>
                                                            <strong>
                                                                {item.owner || `${user?.first_name+' '+user?.last_name}`}
                                                            </strong>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        {formatCurrency(item.amount).with_currency}
                                                    </td>
                                                    <td>
                                                        {formatCurrency(item.used_amount).with_currency}
                                                    </td>
                                                    <td>
                                                        {dateFormatter(item.date)}
                                                    </td>
                                                    <td>
                                                        <p className="d-flex m-0 align-items-center justify-content-between">
                                                            <Badge 
                                                                className={item.valid_detail ? 'badge-active' : 
                                                                (item.used === 'used' ? 'badge-used' : 
                                                                (!item.valid_detail ? 'badge-failed' : 'badge-pending')
                                                                )}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                    <circle cx="5" cy="5" r="5" fill={
                                                                        item.valid_detail ? "#008000" : 
                                                                        (item.used ? "#828282" :  "#FAB941")
                                                                    }
                                                                    />
                                                                </svg>
                                                                <span className="ps-1">
                                                                    {item.valid_detail ? 'Active' : (item.used ? 'Used' : 'Expired')}
                                                                </span>
                                                            </Badge> 
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="light" className="border-0 bg-white" size="sm" id="dropdown-basic">
                                                                    <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                </Dropdown.Toggle>
    
                                                                <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                    <Dropdown.Item 
                                                                        eventKey={1} 
                                                                        className="text-primary d-flex align-items-center gap-1" 
                                                                        onClick={() => selectTransaction(item.id)}
                                                                    >
                                                                    <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Ticket
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item 
                                                                        eventKey={2} 
                                                                        className="d-flex align-items-center gap-1"
                                                                        onClick={ () => selectTransaction(item.id) }
                                                                    >
                                                                        <FontAwesomeIcon icon="folder-open" /> Re-open Ticket
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </p>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                        :
                                        (
                                            <tr>
                                                <td colSpan={5} className="text-center text-muted">No data found</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {
                        vouchers?.length ? (
                            <div className="table-responsive mt-2">
                                <Paginator/>
                            </div>
                        ) : null
                    }
                </div>

                <DrewsModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    dismissable={true}
                    title={transaction.reference}
                >
                    <Row>
                        <Col md={6}>
                            <div className="modal-profile">
                                <ul>
                                    <li>
                                        <div>
                                            <p className="pt-2">
                                                <small className="text-muted">Voucher Balance</small>
                                                {transaction.amount} 
                                            </p>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </Col>
                        <Col md={6} className="modal-profile">
                            <ul>
                                <li>
                                    <div>
                                        <p className="pt-2">
                                            <small className="text-muted">Valid Till</small>
                                            23-08-2023
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </DrewsModal>

                <DrewsModal
                    show={showBalance}
                    onHide={() => setShowBalance(false)}
                    size="md"
                    dismissable={true}
                    title="Generate eVoucher"
                >
                    <Form>
                        <p>Create a bulk voucher easily</p>
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Amount per PayCode</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="0.00"
                                    name="amount"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation} 
                                    value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}
                                />
                                {
                                    errors['amount'] && 
                                    <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Quantity</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="0"
                                    name="qty"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['qty'] && 
                                    <Form.Text className="text-danger">{errors['qty']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Secret Word</Form.Label>
                                <Form.Control
                                    type="password"
                                    required
                                    placeholder="Secret Word"
                                    name="secret"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['secret'] && 
                                    <Form.Text className="text-danger">{errors['secret']}</Form.Text>
                                }
                                <p className="text-muted text-sm mb-0">Create a security word to protect your vouchers</p>
                                <p className="text-danger text-sm">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 8H12.01M12 11V16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#9F0A0A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                   <span className="mx-1">You will be charged 0.2% per Voucher</span> 
                                </p>
                            </Form.Group>

                            <Form.Group 
                                as={Col} 
                                md="12" 
                                controlId="validationCustom011" 
                                className="mt-4 d-grid"
                            >
                                <Button 
                                    variant="primary" 
                                    size="lg" 
                                    onClick={createPayCode}
                                >
                                    Create Payment Link <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>
            </Container>
        </Layout>
    );
}

export default Voucher;
import { Row, Col, Container, Card, Button, Form, Spinner, Modal } from "react-bootstrap";
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import phone from '../../assets/images/bills/phone.svg';
import bulb from '../../assets/images/bills/bulb.svg';
import network from '../../assets/images/bills/network.svg';
import water from '../../assets/images/bills/water.svg';
import tv from '../../assets/images/bills/tv.svg';
import DrewsModal from "../../components/modal/DrewsModal";
import ServiceBox from "../../components/ServiceBox";
import { useDispatch } from "react-redux";
import { getWallets } from "../../redux/slices/users";
import formatCurrency from "../../utils/currencyFormmter";
import WithdrawalPin from "../../components/WithdrawalPin";
import Swal from "sweetalert2";
import { customerDeviceValidation, getServiceProviders, mobileBundles, payBills, tvBouque } from "../../redux/slices/billspayment.slice";
import Select from 'react-select'
import Logo from "../../components/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const BillsPayment = () => {

    const dispatch = useDispatch()

    const [wallet, setWallet] = useState({})

    useEffect(() => {
        loadWallets()
        loadProviders('airtime')
    }, [])


    const services = [
        {
            id: 1,
            name: 'Airtime',
            description:'Top up your line for calls and SMS',
            avatar: phone,
            buttonText: 'Buy Airtime',
            slug: 'airtime',
        },
        {
            id: 2,
            name: 'Mobile Data',
            description:'Buy Data to stay connected',
            avatar: network,
            buttonText: 'Buy Data',
            slug: 'mobile_data'
        },
        {
            id: 3,
            name: 'Electricity',
            description:'Pay your electricity bills and stay fully alive',
            avatar: bulb,
            buttonText: 'Pay Electricity Bills',
            slug: 'electricity'
        },
        {
            id: 4,
            name: 'TV Subscription',
            description:'Subscribe to your channels, never miss your shows',
            avatar: tv,
            buttonText: 'Pay TV Bills',
            slug: 'cable_tv'
        },
        {
            id: 5,
            name: 'Water Bills',
            description:'Subscribe to your channels, never miss your shows',
            avatar: water,
            buttonText: 'Pay Water Bills',
            slug: 'water_bill'
        },
    ];

    const [showForm, setForm] = useState(false)
    const [show, setShow] = useState(false)
    const [transaction_pin, setTransactionPin] = useState('')
    const [errors, setErrors] = useState({provider: 'Select a provider'})
    const [payload, setPayload] = useState({amount: null})
    const [loading, setLoading] = useState(false)
    const [is_active, setIsActive] = useState(null)
    const [selected_service, setSelectedService] = useState(services[0])
    const [service_providers, setServiceProviders] = useState([])
    const [data_bundles, setDataBundles] = useState([])
    const [bouquets, setBouquets] = useState([])
    const [verification_data, setVerificationData] = useState({})
    const [receipt, setReceipt] = useState({})
    const [show_receipt, setShowReceipt] = useState(false)
    const [pricing_options, setPricingOptions] = useState([])
    const [bundleOptions, setBundleOptions] = useState([])
    const [copiedText, setCopiedText] = useState('');

    const loadWallets = () => {
        dispatch(getWallets())
        .then((res) => { 
            if(res.payload?.length) {
                setWallet(res.payload[0])
            }
        })
    }

    const loadProviders = (service) => {
        dispatch(getServiceProviders(service))
        .then(results => {
            if(results.payload.data.providers) {
                // console.log(results.payload.data.providers)
                const options =  results.payload.data.providers?.map(provider =>  { 
                    return {value: provider.service_type, label: provider.name} 
                 })
                setServiceProviders(options)
                // setServiceProviders(results.payload.data.providers)
            }
        })
    }

    const loadDataBundles = (service) => {
        dispatch(mobileBundles(service))
        .then(results => {
            // console.log(results)
            if(results.payload.data) {
                setDataBundles(results.payload.data)
                let param = selected_service.slug === 'mobile_data' ? 'datacode' : 'code'
                const options =  results.payload.data?.map(bundle =>  { 
                    return {value: bundle[param], label: bundle.name} 
                 })
                setBundleOptions(options)
            }
        })
    }

    const loadBouquets = (service) => {
        dispatch(tvBouque(service))
        .then(results => {
            if(results.payload.data) {
                setBouquets(results.payload.data)
                const options =  results.payload.data?.map(bundle =>  { 
                    return {value: bundle.code, label: bundle.name} 
                 })
                setBundleOptions(options)
            }
        })
    }

    const getPinValues = (data) => {
        setTransactionPin(data)
    }

    const handlePurchase = () => {
        setLoading(true)
        let request_payload = payload
        request_payload.service = selected_service.slug
        request_payload.pin = transaction_pin
        
        dispatch(payBills(request_payload))
        .then( results => {
            if(results.payload.success && results.payload.success == true) {
                let message = results.payload.data.transactionMessage
                if(selected_service.slug === 'electricity') {
                    message = `Token purchase successful. Token: ${results.payload.data.tokenCode}  ${results.payload.data.amountOfPower}` 
                    setReceipt(results.payload.data)
                    setShowReceipt(true)
                }
                Swal.fire({
                    icon: "success",
                    text: message
                })
                .then(() => {
                    setVerificationData({})
                    setForm(false)
                    loadWallets()
                })
            }
            else {
                Swal.fire({
                    icon: "error",
                    text: results.payload.message
                })
            }
        })
        .finally(() => setLoading(false))

    }

    const handleInput = (event) => {
        const { name, value } = event.target
        if(name === 'amount' || name === 'fund_amount') {
            const newValue = value.replace(/[^\d.]/g, '')
            setPayload((prevState)=> ({...prevState,[name]:newValue}))
        }
        else {
            setPayload((prevState)=> ({...prevState,[name]:value}))
        }

        if(payload.meter_number && name === 'meter_number' && payload.meter_number.length >= 10) {
            handleVerification() 
        }

        if(payload.card_number && name === 'card_number' && payload.card_number.length >= 10) {
            handleVerification() 
        }
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target

        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else if ((name ==='amount' || name === 'fund_amount') && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if (name ==='amount' && parseFloat(value.replace(/[^\d.]/g, '')) > 200000) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: 'You can not withdraw above maximum limit' }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }

        // if(name === 'amount' && parseFloat(value.replace(/[^\d.]/g, '')) > 0) {
        //     calculateFees()
        // }

    }

    const setCustomPrice = (amount) => {
        setPayload({...payload, amount})
        setIsActive(amount)
    }

    const serviceSelection = (service) => {
        setSelectedService(service);
        setServiceProviders([])
        setPayload({...payload, amount: 0 })
        if(service !== '' && service.slug !== 'water_bill') {
            loadProviders(service.slug)
        }
        setForm(true)
        setErrors((prevState) => ({ ...prevState, ['provider']: 'Select a provider'}))
    }

    const handleProviderSelection = (e) => {
        let providerId = e.value
        setPayload({...payload, provider : providerId })
        if(selected_service.slug == 'mobile_data') {
            loadDataBundles(providerId)
        }
        else if(selected_service.slug == 'cable_tv') {
            loadBouquets(providerId)
        }
        delete errors['provider']
        setErrors(errors)
    }

    const handleDataBundleSelection = (e) => {
        const value = e.value
        // const value = e.target.value
        if(selected_service.slug == 'mobile_data') {
            let selected = data_bundles.filter(bundle => bundle.datacode == value)[0]
            setPayload({...payload, product_code: value, amount : selected.price })
        }
        else if(selected_service.slug == 'cable_tv') {
            let selected = bouquets.filter(bouque => bouque.code == value)[0]
            let plan = selected.availablePricingOptions.filter(pricing => pricing.monthsPaidFor == 1)[0]
            setPayload({...payload, product_code: value, amount: plan.price })

            const options =  selected.availablePricingOptions?.map(pricing =>  { 
                return {value: pricing.monthsPaidFor, label: pricing.monthsPaidFor+'month(s) - '+formatCurrency(pricing.price).with_currency} 
             })

            setPricingOptions(options)
            // setPricingOptions(selected.availablePricingOptions)
        }
    }

    const handlePreiodSelection = (e) => {
        const value = e.value
        if(value !== " ") {
            let bouquet = bouquets.filter(bouque => bouque.code == payload.product_code)[0]
            let plan = bouquet.availablePricingOptions.filter(pricing => pricing.monthsPaidFor == value)[0]
            setPayload({...payload, amount: plan.price, product_months_paid_for: value })
        }
    }
    

    const handleVerification = () => {
        setLoading(true)
        let request_payload = payload
        payload.service = selected_service.slug
        const name = selected_service.slug == 'electricity' ? 'meter_number' : 'card_number'

        dispatch(customerDeviceValidation(request_payload))
        .then(results => {
            if(results.payload.success && results.payload.success == true) {
                const data = selected_service.slug === 'electricity' ? results.payload.data : results.payload.data.user
                setVerificationData(data)
                delete errors[name]
                setErrors(errors)
            }
            else {
                setVerificationData({})
                setErrors((prevState) => ({ ...prevState, [name]: `Could not verify ${name.split('_').join(' ')}`}))
            }
        })
        .finally(() => setLoading(false))
    }

    const handleCopy = (copyText) => {
        navigator.clipboard.writeText(copyText)
          .then(() => {
            setCopiedText(copyText);
          })
          .catch(err => {
            console.error('Error copying text: ', err);
          });
      };

    return (
        <Layout>
            <Container fluid className="mt-4 px-4 mb-4">
                <Col md={12} className="d-flex align-items-center justify-content-between">
                    <h3>Bills Payment</h3>
                </Col>

                <div className="d-grid gap-4 mt-4">
                    <Row className="data-card">
                        <Col md={6} xs={12}>
                            <Card className="border-0 shadow-sm bg--ldight">
                                <Card.Body className="d-flex align-items-center gap-3 py-4 pe-3 ps-3">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                            <path d="M39.8201 8.18C39.2209 8.0597 38.6112 7.9994 38.0001 8H14.0001C13.4696 8 12.9609 7.78929 12.5859 7.41421C12.2108 7.03914 12.0001 6.53043 12.0001 6C12.0001 5.46957 12.2108 4.96086 12.5859 4.58579C12.9609 4.21071 13.4696 4 14.0001 4H38.0001C37.0686 2.75804 35.8608 1.75001 34.4722 1.05573C33.0837 0.361451 31.5525 0 30.0001 0H10.0001C8.44763 0 6.9165 0.361451 5.52795 1.05573C4.13939 1.75001 2.93155 2.75804 2.00008 4C0.693994 5.72683 -0.00869051 7.83488 8.11355e-05 10V38C8.11355e-05 40.6522 1.05365 43.1957 2.92901 45.0711C4.80438 46.9464 7.34792 48 10.0001 48H38.0001C40.6522 48 43.1958 46.9464 45.0711 45.0711C46.9465 43.1957 48.0001 40.6522 48.0001 38V18C47.997 15.6658 47.1776 13.4062 45.6836 11.6127C44.1896 9.81919 42.1153 8.60483 39.8201 8.18ZM34.4801 34C34.3207 34.0193 34.1595 34.0193 34.0001 34C32.4088 34 30.8827 33.3679 29.7574 32.2426C28.6322 31.1174 28.0001 29.5913 28.0001 28C28.0001 26.4087 28.6322 24.8826 29.7574 23.7574C30.8827 22.6321 32.4088 22 34.0001 22C34.6832 21.9957 35.3613 22.1177 36.0001 22.36C37.2747 22.8321 38.3513 23.7229 39.0536 24.8868C39.7558 26.0506 40.0421 27.4183 39.8655 28.7661C39.6889 30.1138 39.0599 31.3617 38.0815 32.3053C37.1031 33.2489 35.8333 33.8323 34.4801 33.96V34Z" fill="#B2B1FF"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <h4>{formatCurrency(wallet?.amount).with_currency}</h4>
                                        <p className="m-0">Avail. Wallet balance</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {
                        services.length ? (
                            <div className="services">
                                {
                                    services.map((service, index) => (
                                        <ServiceBox 
                                            key={index}
                                            text={service.description}
                                            buttonText={service.buttonText}
                                            onClick={() => serviceSelection(service)}
                                        >
                                            <img src={service.avatar} alt={service.name} />
                                        </ServiceBox>
                                    ))
                                }
                            </div>

                        ) : null
                    }
                </div>

                <Modal 
                    show={show_receipt} 
                    fullscreen={true} 
                    onHide={() => setShowReceipt(false)}
                    dialogClassName="receipt-screen-modal"
                    backdrop="static"
                >
                    <Modal.Header>
                        <button className="btn-plain">
                            <FontAwesomeIcon icon="chevron-left" onClick={ ()=>setShowReceipt(false) }/>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="p-2">
                        <div className="invoice px-3 py-0 border border-0">
                            <div className="border-bottom border-bottom-1 d-flex align-items-center justify-content-between pb-2 py-2">
                                <Logo variant="dark"/>
                                <div>
                                    <h5 className="mb-0 text-right">Transaction Receipt</h5>
                                    <p className="text-muted text-sm m-0 text-right">Generated: {receipt?.date || ''}</p>
                                </div>
                            </div>
                            <div className="table-responsive mb-3">
                                <table className="table border-0">
                                    <tbody>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Transaction Amount
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {formatCurrency(payload.amount).with_currency}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Transaction Type
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {payload?.service}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Service Provider
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {payload?.provider}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Date
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {receipt?.date}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Meter No:
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {payload?.meter_number}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Token
                                            </td>
                                            <td className="text-primary border-0 text-right copy-text">
                                                {receipt?.tokenCode || 'Nill'} 
                                                <button className="btn-plain">
                                                    <FontAwesomeIcon icon={"copy"} className="text-success" onClick={ ()  => handleCopy(receipt?.tokenCode || 'Nill') }/>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Transaction ID
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {receipt?.transactionReference}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Customer Name
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {verification_data?.name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted text-sm w-50 border-0">
                                                Address
                                            </td>
                                            <td className="text-primary border-0 text-right">
                                                {verification_data?.address}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <DrewsModal
                    show={showForm}
                    onHide={() => setForm(false)}
                    size="md"
                    dismissable={true}
                    title={`${selected_service.buttonText}`}
                >
                    <Form>
                        <p className="text-sm">Available Balance: <strong>{formatCurrency(wallet?.amount).with_currency}</strong> </p>
                        {
                            selected_service && selected_service.slug == 'airtime' ?
                            <>
                                <div className="d-flex mb-2">
                                    <button type="button" className={`btn-outline-light text-dark btn btn-sm me-1 px-3 py-1 ${is_active == 100 ? 'active-btn' : ''}`} onClick={() => setCustomPrice(100)}>N100</button>
                                    <button type="button" className={`btn-outline-light text-dark btn btn-sm me-1 px-3 py-1 ${is_active == 200 ? 'active-btn' : ''}`} onClick={() => setCustomPrice(200)}>N200</button>
                                    <button type="button" className={`btn-outline-light text-dark btn btn-sm me-1 px-3 py-1 ${is_active == 500 ? 'active-btn' : ''}`} onClick={() => setCustomPrice(500)}>N500</button>
                                    <button type="button" className={`btn-outline-light text-dark btn btn-sm me-1 px-3 py-1 ${is_active == 1000 ? 'active-btn' : ''}`} onClick={() => setCustomPrice(1000)}>N1000</button>
                                </div>
                                <Row>
                                    <Form.Group as={Col} md={12} className="mb-3">
                                        <Form.Label className="mb-0">Amount(₦)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            placeholder="0.00"
                                            name="amount"
                                            onChange={ handleInput }
                                            onKeyUp={ handleValidation }
                                            value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}
                                        />
                                        {
                                            errors['amount'] && 
                                            <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                        }
                                    </Form.Group>
                                    
                                    <Form.Group as={Col} md={12} className="mb-3">
                                        <Form.Label className="mb-0">Service Provider</Form.Label>
                                        <Select 
                                            name="provider" 
                                            options={service_providers}
                                            onChange={ handleProviderSelection }
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    height: "45px",
                                                    backgroundColor: "#f2f2f2"
                                                }),
                                            }}
                                        />
                                        {
                                            errors['provider'] && 
                                            <Form.Text className="text-danger">{errors['provider']}</Form.Text>
                                        }
                                    </Form.Group>

                                    <Form.Group as={Col} md={12} className="mb-3">
                                        <Form.Label className="mb-0">Phone Number</Form.Label>
                                        <Form.Control
                                            type="tel"
                                            required
                                            placeholder="08012345678"
                                            name="phone_number"
                                            maxLength={11}
                                            minLength={11}
                                            onChange={ handleInput }
                                            onKeyUp={ handleValidation }
                                        />
                                        {
                                            errors['phone_number'] && 
                                            <Form.Text className="text-danger">{errors['phone_number']}</Form.Text>
                                        }
                                    </Form.Group>

                                    <WithdrawalPin 
                                        sendPinValue={ getPinValues }
                                    />

                                    <Form.Group 
                                        as={Col} 
                                        md={12} 
                                        className="mt-4 d-grid"
                                    >
                                        <Button 
                                            type="button"
                                            variant="primary" 
                                            size="lg" 
                                            onClick={handlePurchase}
                                            disabled={Object.keys(errors).length > 0 || transaction_pin.length < 4}
                                        >
                                            Buy Airtime <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                        </Button>
                                    </Form.Group>
                                </Row>
                            </> : null
                        }
                        {
                            selected_service && selected_service.slug == 'mobile_data' ?
                                <Row>
                                    <Form.Group as={Col} md={12} className="mb-3">
                                        <Form.Label className="mb-0">Service Provider</Form.Label>
                                        <Select 
                                            name="provider" 
                                            options={service_providers}
                                            onChange={ handleProviderSelection }
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    height: "45px",
                                                    backgroundColor: "#f2f2f2"
                                                }),
                                            }}
                                        />
                                        {
                                            errors['provider'] && 
                                            <Form.Text className="text-danger">{errors['provider']}</Form.Text>
                                        }
                                    </Form.Group>

                                    <Form.Group as={Col} md={12} className="mb-3">
                                        <Form.Label className="mb-0">Data Bundle</Form.Label>
                                        {/* <Form.Select className="form-control"  name="bundle"                                    
                                            onChange={ handleDataBundleSelection }
                                        >
                                            <option value="">---Select Bundle ---</option>
                                            {
                                                data_bundles.length ? 
                                                    data_bundles.map((item, indexx) => (
                                                        <option key={indexx+10} value={item.datacode}>{ item.name }</option>
                                                    )) 
                                                : null
                                            }
                                        </Form.Select> */}
                                        <Select 
                                            name="bundle" 
                                            options={bundleOptions}
                                            onChange={ handleDataBundleSelection }
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    height: "45px",
                                                    backgroundColor: "#f2f2f2"
                                                }),
                                            }}
                                        />
                                        {
                                            errors['bundle'] && 
                                            <Form.Text className="text-danger">{errors['bundle']}</Form.Text>
                                        }
                                    </Form.Group>

                                    <Form.Group as={Col} md={12} className="mb-3">
                                        <Form.Label className="mb-0">Amount(₦)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            placeholder="0.00"
                                            name="amount"
                                            onChange={ handleInput }
                                            onKeyUp={ handleValidation }
                                            value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}
                                            readOnly
                                        />
                                        {
                                            errors['amount'] && 
                                            <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                        }
                                    </Form.Group>

                                    <Form.Group as={Col} md={12} className="mb-3">
                                        <Form.Label className="mb-0">Phone Number</Form.Label>
                                        <Form.Control
                                            type="tel"
                                            required
                                            placeholder="08012345678"
                                            name="phone_number"
                                            maxLength={11}
                                            minLength={11}
                                            onChange={ handleInput }
                                            onKeyUp={ handleValidation }
                                        />
                                        {
                                            errors['phone_number'] && 
                                            <Form.Text className="text-danger">{errors['phone_number']}</Form.Text>
                                        }
                                    </Form.Group>

                                    <WithdrawalPin 
                                        sendPinValue={ getPinValues }
                                    />

                                    <Form.Group 
                                        as={Col} 
                                        md={12} 
                                        className="mt-4 d-grid"
                                    >
                                        <Button 
                                            type="button"
                                            variant="primary" 
                                            size="lg" 
                                            onClick={handlePurchase}
                                            disabled={Object.keys(errors).length > 0 || transaction_pin.length < 4}
                                        >
                                            Buy Data <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                        </Button>
                                    </Form.Group>
                                </Row>
                            : null
                        }
                        {
                            selected_service && selected_service.slug == 'electricity' ?
                                <Row>
                                    <Form.Group as={Col} md={12} className="mb-3">
                                        <Form.Label className="mb-0">Service Provider</Form.Label>
                                        <Select 
                                            name="provider" 
                                            options={service_providers}
                                            onChange={ handleProviderSelection }
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    height: "45px",
                                                    backgroundColor: "#f2f2f2"
                                                }),
                                            }}
                                        />
                                        {
                                            errors['provider'] && 
                                            <Form.Text className="text-danger">{errors['provider']}</Form.Text>
                                        }
                                    </Form.Group>

                                    <Form.Group as={Col} md={12} className="mb-3">
                                        <Form.Label className="mb-0">Meter Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            placeholder="Meter Number"
                                            name="meter_number"
                                            onChange={ handleInput }
                                            onKeyUp={ handleValidation }
                                        />
                                        {
                                            verification_data && verification_data.name ?
                                            <>
                                                <Form.Text className="text-success">{verification_data.name}</Form.Text> <br/> 
                                                <Form.Text className="text-success">{verification_data.address}</Form.Text> 
                                            </> : null
                                        }
                                        {
                                            errors['meter_number'] && 
                                            <Form.Text className="text-danger">{errors['meter_number']}</Form.Text>
                                        }
                                    </Form.Group>

                                    <Form.Group as={Col} md={12} className="mb-3">
                                        <Form.Label className="mb-0">Amount(₦)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            placeholder="0.00"
                                            name="amount"
                                            onChange={ handleInput }
                                            onKeyUp={ handleValidation }
                                            value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}
                                        />
                                        {
                                            errors['amount'] && 
                                            <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                        }
                                    </Form.Group>

                                    <WithdrawalPin 
                                        sendPinValue={ getPinValues }
                                    />

                                    <Form.Group 
                                        as={Col} 
                                        md={12} 
                                        className="mt-4 d-grid"
                                    >
                                        <Button 
                                            type="button"
                                            variant="primary" 
                                            size="lg" 
                                            onClick={handlePurchase}
                                            disabled={Object.keys(errors).length > 0 || transaction_pin.length < 4}
                                        >
                                            Pay Bill <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                        </Button>
                                    </Form.Group>
                                </Row>
                            : null
                        }
                        {
                            selected_service && selected_service.slug == 'cable_tv' ?
                                <Row>
                                    <Form.Group as={Col} md={12} className="mb-3">
                                        <Form.Label className="mb-0">Service Provider</Form.Label>
                                        <Select 
                                            name="provider" 
                                            options={service_providers}
                                            onChange={ handleProviderSelection }
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    height: "45px",
                                                    backgroundColor: "#f2f2f2"
                                                }),
                                            }}
                                        />
                                        {
                                            errors['provider'] && 
                                            <Form.Text className="text-danger">{errors['provider']}</Form.Text>
                                        }
                                    </Form.Group>

                                    <Form.Group as={Col} md={12} className="mb-3">
                                        <Form.Label className="mb-0">Select Plan</Form.Label>
                                         <Select 
                                            name="plan" 
                                            options={bundleOptions}
                                            onChange={ handleDataBundleSelection }
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    height: "45px",
                                                    backgroundColor: "#f2f2f2"
                                                }),
                                            }}
                                        />
                                        {
                                            errors['plan'] && 
                                            <Form.Text className="text-danger">{errors['plan']}</Form.Text>
                                        }
                                    </Form.Group>

                                    <Form.Group as={Col} md={12} className="mb-3">
                                        <Form.Label className="mb-0">Subscription Period</Form.Label>
                                        <Select 
                                            name="plan" 
                                            options={pricing_options}
                                            onChange={handlePreiodSelection}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    height: "45px",
                                                    backgroundColor: "#f2f2f2"
                                                }),
                                            }}
                                        />
                                        {
                                            errors['plan'] && 
                                            <Form.Text className="text-danger">{errors['plan']}</Form.Text>
                                        }
                                    </Form.Group>

                                    <Form.Group as={Col} md={12} className="mb-3">
                                        <Form.Label className="mb-0">Card Number</Form.Label>
                                        <Form.Control
                                            type="tel"
                                            required
                                            placeholder="Card Number"
                                            name="card_number"
                                            onChange={ handleInput }
                                            onKeyUp={ handleValidation }
                                        />
                                        {
                                            verification_data && verification_data.name ?
                                                <Form.Text className="text-success">{verification_data.name}</Form.Text> 
                                            : null
                                        }
                                        {
                                            errors['card_number'] && 
                                            <Form.Text className="text-danger">{errors['card_number']}</Form.Text>
                                        }
                                    </Form.Group>

                                    <Form.Group as={Col} md={12} className="mb-3">
                                        <Form.Label className="mb-0">Amount(₦)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            placeholder="0.00"
                                            name="amount"
                                            onChange={ handleInput }
                                            onKeyUp={ handleValidation }
                                            value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}
                                            readOnly
                                        />
                                        {
                                            errors['amount'] && 
                                            <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                        }
                                    </Form.Group>

                                    <WithdrawalPin 
                                        sendPinValue={ getPinValues }
                                    />

                                    <Form.Group 
                                        as={Col} 
                                        md={12} 
                                        className="mt-4 d-grid"
                                    >
                                        <Button 
                                            type="button"
                                            variant="primary" 
                                            size="lg" 
                                            onClick={handlePurchase}
                                            disabled={Object.keys(errors).length > 0 || transaction_pin.length < 4}
                                        >
                                            Pay Bill <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                        </Button>
                                    </Form.Group>
                                </Row>
                            : null
                        }

                        {
                            selected_service && selected_service.slug == 'water_bill' ? 
                                <h4 className="text-center">This service will be available soon.</h4>
                            : null
                        }

                    </Form>
                </DrewsModal>
            </Container>
        </Layout>
    );
}

export default BillsPayment;
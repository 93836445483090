import { Row, Col, Container, Card, Table, Spinner } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import formatCurrency from "../../utils/currencyFormmter";
import InventoryFilter from "../../components/InventoryFilter";
import { useNavigate } from "react-router-dom";
import { getProductPrices, getProducts, getSuppliers, loginCustomer, restock, setupInventory } from "../../redux/slices/inventory.slice";

const VendorList = () => {

    const dispatch = useDispatch();

    const initialState = {
        loading: false,
        show_vendor_form: false,
        showStockForm: false,
        showForm: false,
        analytics: {},
        paginationData: {next: '', prev: ''},
        payload: {
            to_currency: 'NGN', 
            currency: 'USD', 
            amount: 1,
            page: 1,
            from_date: '',
            to_date: '',
            status: '',
            download: '',
            filter_by: '',
            sort_by: '',
            filter: '',
            gateway: 'FasePay',
        },
        errors: {},
        suppliers: [],
        products: [],
        restockMode: false,
        product: {},
        pricelist: [],
        vendors: []
    }

    const [state, setState] = useState(initialState)
    const [loading, setLoading] = useState(false)

    const { invLogin } = useSelector((state) => state.inventory)
    const { user } = useSelector((state) => state.auth)
    const { business } = useSelector((state) => state.user)


    useEffect(() => {
        loadPrices()
    }, [])

    const handleInput = (event) => {
        const {name, value} = event.target
         if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'));
            setState((prevState) => ({...prevState, payload: { ...prevState.payload, [name]: newValue}}))
        }
        else {
            setState((prevState) => ({...prevState, payload: { ...prevState.payload, [name]: value}}))
        }
    }

    const handleLogin = () => {
        if(invLogin) {
            return;
        }
        dispatch(loginCustomer({email: user?.email, password: business?.merchant_id}))
        .then((response) => {
            if(response.payload?.data) {
            }
        })
    }

    const handleCreateOrganisation = () => {
        dispatch(setupInventory({
            business_name:  business?.store_name,
            first_name: user?.first_name,
            last_name: user?.last_name,  
            email: user?.email,
            phone_number: user?.mobile,   
            wallx_id: business?.merchant_id,
            user_type: user?.user_type,
        }))
        .then((response) => {
            if(response.payload?.data) {
                handleLogin()
            }
        })
    }

    const loadPrices = () => {
        setLoading(true)
        dispatch(getSuppliers())
        .then((response) => {
            const result = response.payload?.data?.data
            if(result) {
                setState((prevState) => ({...prevState, vendors:  result})) 
            }
        })
        .finally(() => setLoading(false))
    }

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <Col md={12} className="row align-items-center justify-content-between flex-wrapd">
                    <div className="col-md-4">
                        <h3 className="m-0">List of suppliers</h3>
                    </div>
                </Col>

                <div className="mt-4">
                    {/* <Row className="mb-4 align-items-center justify-content-between">
                        <div className="col-md-5">
                            <h5 className="mb-2">Search Inventory</h5>
                        </div>
                        <div className="">
                            <InventoryFilter 
                                handleInput={handleInput}
                                handleSearchSubmit={handleSearch}
                            />
                        </div>
                    </Row> */}

                    <Card className="border-0 shadow-sm rounded-14">
                        <Card.Body className="table-responsive-lg">
                            <Table size="sm" className="table-color table-stripedd">
                                    <thead>
                                        <tr>
                                            <th>SN</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone Number</th>
                                        </tr>
                                    </thead>
                                    <tbody className="data-container">
                                        {
                                            loading ? (
                                                <div className="in-page-loader">
                                                    <Spinner size="lg" variant="primary"/>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            state.vendors?.length ? (
                                                state.vendors?.map((item, index) => (
                                                    <tr key={index}> 
                                                        <td>
                                                            {index+1}
                                                        </td>
                                                        <td>{item.name}</td>
                                                        <td>{item.email} </td>
                                                        <td>{item.phone_number} </td>
                                                    </tr>
                                                ))
                                            )
                                            : 
                                            (
                                                <tr>
                                                    <td colSpan={5} className="text-center text-muted">No data found</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </Layout>
    );
}

export default VendorList;
import InventoryServiceHelper from './service.inventory.helper';

export default class InventoryService {

    static API_URI = 'admin';

    static login(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/sign-in`, payload);
    }

    static signup(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/register`, payload);
    }

    static createAdmin(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/create-admin`, payload);
    }

    static addStock(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/create-product`, payload);
    }

    static getAllStock(params) {
        let request_params = ``;

        if(params.from_date !== '' && params.from_date !== undefined) {
            request_params += `&from_date=${params.from_date}`; 
        }
        if(params.to_date !== '' && params.to_date !== undefined){
            request_params += `&to_date=${params.to_date}`;
        }
        if(params.filter_by === 'type'){
            request_params += `&transaction_type=${params.filter}`;
        }
        if(params.status !== '' && params.status !== undefined) {
            request_params += `&status=${params.status}`;
        }
        if(params.filter !== '' && params.filter !== undefined) {
            request_params += `&filter=${params.filter}`;
        }
        if(params.filter_by !== '' && params.filter_by !== undefined) {
            request_params += `&filter_by=${params.filter_by}`;
        }
        if(params.sort_by !== '' && params.sort_by !== undefined) {
            request_params += `&sort_by=${params.sort_by}`;
        }
        if(params.download !== '' && params.download !== undefined && params.download === 'True') {
            request_params += `&download=${params.download}`;
        }
        if(params.gateway !== '' && params.gateway !== undefined) {
            request_params += `&gateway=${params.gateway}`;
        }
        if(params.page !== '' && params.page !== undefined) {
            request_params += `&page=${params.page}`;
        }
        request_params = request_params.slice(1)
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/all-products?${request_params}`);
    }

    static getPricelist() {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/pricelist/all`);
    }

    static getStockById(id) {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/product/${id}`);
    }

    static updateStock(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/product/${payload.id}`, payload);
    }

    static deleteStock(id) {
        return InventoryServiceHelper.handleDeleteRequest(`${this.API_URI}/product/${id}`);
    }

    static importStock(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/import-product`, payload);
    }

    static filterStock(params) {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/filter-product?page=${params.page}&sku=${params.sku}&name=${params.name}`);
    }

    static uploadProductImage(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/update-product-image/${payload.id}`, payload);
    }

    static scanBarcode(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/product/${payload.id}/barcode`, payload);
    }

    static stockAnalytics() {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/get-analytics`);
    }

    static restockRequest(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/product-restock`, payload);
    }

    static stockRequests() {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/restock`);
    }

    static manageStockRequest(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/restock-management`, payload);
    }

    static createVendor(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/create-vendor`, payload);
    }

    static getVendors() {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/all-vendors`);
    }

    static getVendorById(vendor_id) {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/vendor/${vendor_id}`);
    }

    static updateVendor(payload) {
        return InventoryServiceHelper.handlePutRequest(`${this.API_URI}/vendor/${payload.vendor_id}`, payload);
    }

    static deleteVendor(vendor_id) {
        return InventoryServiceHelper.handleDeleteRequest(`${this.API_URI}/vendor/${vendor_id}`);
    }
    
    static createOrganisation(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/create-organisation/`, payload);
    }
    
    static wallxOnaboading(payload) {
        return InventoryServiceHelper.handlePostRequest('/wallx/onboarding', payload);
    }
    
    static productSale(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/sales`, payload);
    }
    
    static getSalesAnalytics() {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/sales/analytics`);
    }
    
    static getSalesChart() {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/sales/analytics/chart`);
    }
    
    static async downloadSalesChart() {
        const response = await InventoryServiceHelper.handleGetRequest(`${this.API_URI}/sales/analytics/chart/download`, { responseType: 'blob' });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'sales_chart.csv'); 
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
    
    static allSales(payload) {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/sales/list?page=${payload.page}`);
    }

}
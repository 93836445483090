import { Row, Col, Container, Card, Button, Table, Badge, Dropdown, Form, Spinner, Modal, FormGroup } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../components/table/Paginator";
import { useEffect, useState } from "react";
import DrewsModal from "../../components/modal/DrewsModal";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionHistory } from "../../redux/slices/account.service";
import Swal from "sweetalert2";
import formatCurrency from "../../utils/currencyFormmter";
import { withdrawalRequest } from "../../redux/slices/users";
import dateFormatter from "../../utils/dateFormatter";
import InventoryFilter from "../../components/InventoryFilter";
import { useNavigate, useParams } from "react-router-dom";
import { addProduct, createSupplier, getProduct, getProducts, getSuppliers, loginCustomer, restock, setupInventory, updateProduct } from "../../redux/slices/inventory.slice";

const StockDetails = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams()


    const initialState = {
        loading: false,
        show_vendor_form: false,
        showStockForm: false,
        showForm: false,
        analytics: {},
        paginationData: {next: '', prev: ''},
        payload: {
            to_currency: 'NGN', 
            currency: 'USD', 
            amount: 1,
            page: 1,
            from_date: '',
            to_date: '',
            status: '',
            download: '',
            filter_by: '',
            sort_by: '',
            filter: '',
            gateway: 'FasePay',
        },
        errors: {},
        suppliers: [],
        products: [],
        product: {},
        edit_mode:  false,
        restockMode: false
    }

    const [state, setState] = useState(initialState)
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [request_status, setRequestStatus] = useState(false)
    const [paycode, setPaycode] = useState({})

    const { invLogin } = useSelector((state) => state.inventory)
    const { user } = useSelector((state) => state.auth)
    const { business } = useSelector((state) => state.user)


    useEffect(() => {
        getStockProfile()
    }, [])

    const selectTransaction = (item) => {
        setPaycode(item)
        setShow(true)
    }

    const handleInput = (event) => {
        const {name, value} = event.target
         if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'));
            setState((prevState) => ({...prevState, payload: { ...prevState.payload, [name]: newValue}}))
        }
        else {
            setState((prevState) => ({...prevState, payload: { ...prevState.payload, [name]: value}}))
        }
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setState((prevState) => ({ ...prevState, errors:{...prevState.errors, [name]: `${name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} is required` }}))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setState((prevState) => ({ ...prevState, errors: { ...prevState.errors, [name]: 'Please enter a valid email address'}}))
        }
        else if (name ==='amount' && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setState((prevState) => ({ ...prevState, errors: {...prevState.errors, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }}))
        }
        else {
            event.target.style = "border-style: transparent"
            delete state.errors[name]
            setState((prevState) => ({ ...prevState, errors: state.errors }))
        }
    }

    const updateStock = () => {
        const payload = {
            id: state.product.id,
            sku: state.product.sku,
            name: state.product.name,
            quantity: state.product.quantity,
            price: state.product.price,
            sales_price: state.product.sales_price,
            unit: state.product.unit,
            restock_level: state.product.restock_level,
            supply_date: state.product.supply_date,
            expiry_date: state.product.expiry_date,
            vendor_id: state.product.vendor_id,
            description: state.product.description,
        }
        setLoading(true)
        dispatch(updateProduct(payload))
        .then((response) => {
            // console.log(response)
            if(response.payload?.status) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload.message,
                })
                loadSuppliers()
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: response.payload.message,
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const getStockProfile = () => {
        setLoading(true)
        dispatch(getProduct(id))
        .then((response) => {
            // console.log("PRODUCT", response)
            if(response.payload?.data) {
                setState((prevState) => ({...prevState,  product: response.payload.data}))
            }
        })
        .finally(() => setLoading(false))
    }

    const loadSuppliers = () => {
        dispatch(getSuppliers())
        .then((response) => {
            const result = response.payload?.data
            // console.log("Suppliers", result?.data)
            if(result?.data) {
                setState((prevState) => ({...prevState, suppliers:  result?.data}))
            }
        })
    }

    const sendRestockRequest = () => {
        setRequestStatus(true)
        dispatch(restock({product_id: state.product.id, reason: state.payload.reason,  quantity: state.payload.quantity}))
        .then((response) => {
            if(response.payload?.status === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                })
                .then(() => {
                    setState((prevState) => ({
                        ...prevState,
                        restockMode: false
                    }))
                })
            }
            else if(response.payload?.status === false) {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
        .finally(() => setRequestStatus(false))
    }

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <div className="mt-4">
                    <Card className="border-0 shadow-sm rounded-14">
                        <Card.Header>
                            <Row className="align-items-center justify-content-between">
                                <div className="col-md-6 d-flex align-items-center">
                                    <Button
                                        type="button"
                                        variant="primary"
                                        className="border-0 mb-2 me-2"
                                        onClick={() => navigate('/inventory')}
                                    >
                                        <FontAwesomeIcon icon={'chevron-left'} /> 
                                        <span className="ps-2">Go Back</span>
                                    </Button>
                                    <h5 className="mb-2">Product Details</h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <Button
                                        type="button"
                                        variant="primary"
                                        className="border-0 mb-2"
                                        onClick={(e) => setState((prevState) => ({
                                            ...prevState,
                                            restockMode: !prevState.restockMode
                                        }))}
                                    >
                                        <FontAwesomeIcon icon={'box'} /> 
                                        <span className="ps-2">Stock Request</span>
                                    </Button>
                                </div>
                            </Row>
                        </Card.Header>
                        <Card.Body className="table-responsive-lg data-container">
                            {
                                loading ? (
                                    <div className="in-page-loader">
                                        <Spinner size="lg" variant="primary"/>
                                    </div>
                                ) : null
                            }
                            <Row className="mb-3 justify-content-center">
                                <Col md={12} className="text-center mb-3 mt-3">
                                    <img src={state.product.image} className="img-fluid" alt="Product details" />
                                </Col>
                                <Col md={6}>
                                    <Row className="mb-3">
                                        <Col md={6} className="mb-2">
                                            <Form.Group>
                                                <Form.Label className="mb-0">Product Code</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="sku"
                                                    readOnly={!state.edit_mode}
                                                    value={state.product.sku}
                                                    onChange={(e) => setState((prevState) => ({
                                                        ...prevState,
                                                        product: { 
                                                            ...prevState.product, 
                                                            [e.target.name]: e.target.value 
                                                        }
                                                    }))}                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <Form.Group>
                                                <Form.Label className="mb-0">Product Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="product_name"
                                                    readOnly={!state.edit_mode}
                                                    value={state.product.name}
                                                    onChange={(e) => setState((prevState) => ({
                                                        ...prevState,
                                                        product: { 
                                                            ...prevState.product, 
                                                            [e.target.name]: e.target.value 
                                                        }
                                                    }))}                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <Form.Group>
                                                <Form.Label className="mb-0">Quantity</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="quantity"
                                                    readOnly={!state.edit_mode}
                                                    min={0}
                                                    value={state.product.quantity}
                                                    onChange={(e) => setState((prevState) => ({
                                                        ...prevState,
                                                        product: { 
                                                            ...prevState.product, 
                                                            [e.target.name]: e.target.value 
                                                        }
                                                    }))}                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <Form.Group>
                                                <Form.Label className="mb-0">Unit</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="unit"
                                                    readOnly={!state.edit_mode}
                                                    value={state.product.unit}
                                                    onChange={(e) => setState((prevState) => ({
                                                        ...prevState,
                                                        product: { 
                                                            ...prevState.product, 
                                                            [e.target.name]: e.target.value 
                                                        }
                                                    }))}                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <Form.Group>
                                                <Form.Label className="mb-0">Cost Price</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="price"
                                                    readOnly={!state.edit_mode}
                                                    value={state.product.price}
                                                    onChange={(e) => setState((prevState) => ({
                                                        ...prevState,
                                                        product: { 
                                                            ...prevState.product, 
                                                            [e.target.name]: e.target.value 
                                                        }
                                                    }))}                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <Form.Group>
                                                <Form.Label className="mb-0">Sales Price</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="sales_price"
                                                    readOnly={!state.edit_mode}
                                                    value={state.product.sales_price}
                                                    onChange={(e) => setState((prevState) => ({
                                                        ...prevState,
                                                        product: { 
                                                            ...prevState.product, 
                                                            [e.target.name]: e.target.value 
                                                        }
                                                    }))}                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <Form.Group>
                                                <Form.Label className="mb-0">Supply Date</Form.Label>
                                                <Form.Control
                                                    type={state.edit_mode ? "date" : "text"}
                                                    name="supply_date"
                                                    readOnly={!state.edit_mode}
                                                    value={state.product.supply_date}
                                                    onChange={(e) => setState((prevState) => ({
                                                        ...prevState,
                                                        product: { 
                                                            ...prevState.product, 
                                                            [e.target.name]: e.target.value 
                                                        }
                                                    }))}                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <Form.Group>
                                                <Form.Label className="mb-0">Expiry Date</Form.Label>
                                                <Form.Control
                                                    type={state.edit_mode ? "date" : "text"}
                                                    name="expiry_date"
                                                    readOnly={!state.edit_mode}
                                                    value={state.product.expiry_date}
                                                    onChange={(e) => setState((prevState) => ({
                                                        ...prevState,
                                                        product: { 
                                                            ...prevState.product, 
                                                            [e.target.name]: e.target.value 
                                                        }
                                                    }))}                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <Form.Group>
                                                <Form.Label className="mb-0">Restock Level</Form.Label>
                                                <Form.Control
                                                    type={state.edit_mode ? "number" : "text"}
                                                    name="restock_level"
                                                    readOnly={!state.edit_mode}
                                                    value={state.product.restock_level}
                                                    onChange={(e) => setState((prevState) => ({
                                                        ...prevState,
                                                        product: { 
                                                            ...prevState.product, 
                                                            [e.target.name]: e.target.value 
                                                        }
                                                    }))}                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <Form.Group>
                                                <Form.Label className="mb-0">Supplier</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="vendor_name"
                                                    readOnly={!state.edit_mode}
                                                    value={state.product.vendor_name}
                                                    onChange={(e) => setState((prevState) => ({
                                                        ...prevState,
                                                        product: { 
                                                            ...prevState.product, 
                                                            [e.target.name]: e.target.value 
                                                        }
                                                    }))}                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} className="mb-2">
                                            <Form.Group>
                                                <Form.Label className="mb-0">Description</Form.Label>
                                                <textarea
                                                    name="description"
                                                    className="form-control"
                                                    readOnly={!state.edit_mode}
                                                    value={state.product.description}
                                                    onChange={(e) => setState((prevState) => ({
                                                        ...prevState,
                                                        product: { 
                                                            ...prevState.product, 
                                                            [e.target.name]: e.target.value 
                                                        }
                                                    }))}
                                                ></textarea>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} className="mb-2 text-right px-4">
                                            <Button 
                                                type="button" 
                                                size="sm" 
                                                variant="secondary"
                                                className="me-1 text-white"
                                                onClick={(e) => setState((prevState) => ({
                                                    ...prevState,
                                                    restockMode: !prevState.restockMode
                                                }))}
                                            >
                                                <FontAwesomeIcon icon={'box'} /> 
                                                <span className="ps-2">Stock Request</span>
                                            </Button>
                                            <Button 
                                                type="button" 
                                                size="sm" 
                                                variant="outline-primary"
                                                onClick={() => setState((prevState) => ({...prevState, edit_mode: !prevState.edit_mode}))}
                                            >Edit</Button>
                                            {
                                                state.edit_mode && (
                                                    <Button 
                                                        type="button" 
                                                        size="sm" 
                                                        variant="primary"
                                                        className="ms-2"
                                                        onClick={updateStock}
                                                    >Save Changes</Button>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {/* <Table size="sm" className="table-color table-stripedd">
                                    <thead>
                                        <tr>
                                            <th>SKU</th>
                                            <th>Item Name</th>
                                            <th>Quantity</th>
                                            <th>Buying Price(₦)</th>
                                            <th>Selling Price(₦) <a href="#" className="text-primary"><small>Price list</small></a> </th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            state.products?.length ? (
                                                state.products?.map((item, index) => (
                                                    <tr key={index}> 
                                                        <td>
                                                        <button type="button" className="btn btn-plain text-primary font-bold">
                                                                {item.sku}
                                                            </button> 
                                                        </td>
                                                        <td>{item.name}</td>
                                                        <td> {formatCurrency(item.quantity).formatted}  </td>
                                                        <td>{formatCurrency(item.price).with_currency} </td>
                                                        <td>{formatCurrency(item.sales_price).with_currency} </td>
                                                        <td>
                                                            <div className="d-flex m-0 align-items-center justify-content-between">
                                                                <Badge 
                                                                    className={item.quantity > item.restock_level ? 'badge-active' : 
                                                                    (item.quantity < item.restock_level ? 'badge-failed' : 
                                                                    (item.quantity === 0 ? 'badge-used' : 'badge-pending'))}
                                                                >
                                                                    <FontAwesomeIcon icon={'dot-circle'}
                                                                        color={
                                                                            item.quantity > item.restock_level ? "#008000" : 
                                                                            (item.quantity < item.restock_level ? "#EB5757" : 
                                                                            (item.quantity === 0 ? "#828282" : "#FAB941"))
                                                                        }
                                                                    />
                                                                    <span className="ps-1">
                                                                        {
                                                                            item.quantity > item.restock_level ? "In stock" : 
                                                                            (item.quantity < item.restock_level ? "Low stock" : 
                                                                            (item.quantity === 0 ? "Out of stock" : ""))
                                                                        }
                                                                    </span>
                                                                </Badge> 
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                        <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                    </Dropdown.Toggle>
        
                                                                    <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                        <Dropdown.Item 
                                                                            eventKey={1} 
                                                                            className="text-primary d-flex align-items-center gap-1" 
                                                                            onClick={() => selectTransaction(item.id)}
                                                                        >
                                                                        <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Details
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Divider />
                                                                        <Dropdown.Item 
                                                                            eventKey={2} 
                                                                            className="text-danger d-flex align-items-center gap-1"
                                                                            onClick={ () => selectTransaction(item.id) }
                                                                        >
                                                                            <i className="lni lni-question-circle"></i> Report Transaction
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            )
                                            : 
                                            (
                                                <tr>
                                                    <td colSpan={5} className="text-center text-muted">No data found</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                            </Table> */}
                        </Card.Body>
                    </Card>
                </div>

                <DrewsModal
                    show={state.restockMode}
                    onHide={() => setState((prevState) => ({...prevState, restockMode: false}))}
                    size="md"
                    dismissable={true}
                    title={`Request Re-stock for ${state.product.name}`}
                >
                    <Row>
                        <FormGroup as={Col} md={12} sm={12} className="mb-3">
                            <span> Quantity</span> <span className="text-danger">*</span>
                            <Form.Control
                                type="number"
                                name="quantity"
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                                placeholder="Enter your Product quantity"
                                required
                                min={1}
                            />
                            {
                                state.errors['quantity'] && 
                                <Form.Text className="text-danger">{state.errors['quantity']}</Form.Text>
                            }                            
                        </FormGroup>

                        <FormGroup as={Col} md={12} sm={12} className="mb-3">
                            <span>Reason for restock</span><span className="text-danger">*</span>
                            <textarea
                                name="reason"
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                                placeholder="Reasons for product restock"
                                className="form-control"
                                required
                            ></textarea>
                            {
                                state.errors['reason'] && 
                                <Form.Text className="text-danger">{state.errors['reason']}</Form.Text>
                            }                       
                        </FormGroup>
                        <FormGroup as={Col} md={12} sm={12}>
                            <div className="d-grid">
                                <Button variant="primary" type="submit" onClick={sendRestockRequest}>
                                    Submit <Spinner animation={ request_status ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </div>
                        </FormGroup>
                    </Row>
                </DrewsModal>
            </Container>
        </Layout>
    );
}

export default StockDetails;